.hidden {
    @include media-breakpoint-only(xs) {&-xs {display: none;}}
    @include media-breakpoint-only(sm) {&-sm {display: none;}}
    @include media-breakpoint-only(md) {&-md {display: none;}}
    @include media-breakpoint-only(lg) {&-lg {display: none;}}
    @include media-breakpoint-only(xl) {&-xl {display: none;}}
    @include media-breakpoint-down(xs) {&-xs-down {display: none;}}
    @include media-breakpoint-down(sm) {&-sm-down {display: none;}}
    @include media-breakpoint-down(md) {&-md-down {display: none;}}
    @include media-breakpoint-down(lg) {&-lg-down {display: none;}}
    @include media-breakpoint-up(xs) {&-xs-up {display: none;}}
    @include media-breakpoint-up(sm) {&-sm-up {display: none;}}
    @include media-breakpoint-up(md) {&-md-up {display: none;}}
    @include media-breakpoint-up(lg) {&-lg-up {display: none;}}
    @include media-breakpoint-up(xl) {&-xl-up {display: none;}}
}

.page-item {
    &.active {
        .page-link {
            background-color: $main_color;
            border-color: $main_color_dark;
        }
    }
    .page-link {
        &:hover {
            color: $main_color;
        }
        &:focus {
            box-shadow: 0 0 0 0.2rem transparentize($main_color_light, 0.8)
        }
    }
}

.nav-tabs {
    border-bottom: 1px solid $main_color;
    .nav-link.active, 
    .nav-tabs .nav-item.show .nav-link {
        border-color: $main_color $main_color $white;
    }
    .nav-tabs .nav-link:hover,
    .nav-tabs .nav-link:focus {
        border-color: $main_color_light $main_color_light #dee2e6;
    }
}

#nav-tab {
    a.btn {
        margin-right: 15px;
    }
    button.btn {
        margin-left: 15px;
    }
}

.form-control {
    &:focus {
        box-shadow: 0 0 0 0.2rem transparentize($main_color_light, 0.8);
        border-color: $main_color_light;
    }
    option:disabled{
        display: none;
    }
}

.file-group {
    position: relative;
    .browse {
        position: absolute;
        left: 0;
        bottom: 0;
        border-radius: 0;
    }
}