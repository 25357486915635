.main-header {
	.navbar {
		background-color: $navbar_background;
		@include transition(margin-left 0.3s ease-in-out);
		margin-bottom: 0;
		@media (min-width: 768px) {
			margin-left: 230px;
		}
		border: none;
		min-height: 50px;
		padding: 0 20px 0 0;
		@include border-radius(0);
		.nav > li {
			&.user > a > {
				.fa {
					margin-right: 5px;
				}
				.glyphicon {
					margin-right: 5px;
				}
				.ion {
					margin-right: 5px;
				}
			}
			> a > .label {
				position: absolute;
				top: 9px;
				right: 7px;
				text-align: center;
				font-size: 9px;
				padding: 2px 3px;
				line-height: .9;
			}
		}
	}
	.nav-item {
		> a {
			color: $sidebar-text-color-light;
			&:hover {
				background-color: $sidebar-background-color-light;
			}
		}
		.dropdown-menu {
			padding: 10px 10px;
			min-width: 200px;
		}
	}
	#navbar-search-input {
		&.form-control {
			background: $white_20;
			border-color: transparent;
			&:active {
				border-color: $black_10;
				background: $white_90;
			}
			&:-ms-input-placeholder {
				color: $text_gray;
			}
		}
		&.form-control::-moz-placeholder {
			color: $text_gray;
			opacity: 1;
		}
		&.form-control::-webkit-input-placeholder {
			color: $text_gray;
		}
	}
	.navbar-custom-menu {
		float: right;
	}
	.navbar-right {
		float: right;
	}
}
.navbar-toggle {
	color: $white;
	border: 0;
	margin: 0;
	padding: 15px;
}
.navbar-nav {
	display: block;
}
.navbar-nav > {
	.user-menu {
		.nav-item a {
			width: 100%;
			color: $main_color;
		}
		.user-image {
			float: left;
			width: 25px;
			height: 25px;
			@include border-radius(50%);
			margin-right: 10px;
			margin-top: -2px;
		}
		> .dropdown-menu {
			padding: 1px 0 0;
			border-top-width: 0;
			width: 280px;
			@include border-radius(0 0 4px 4px);
			> {
				.user-body {
					@include border-radius(0 0 4px 4px);
					padding: 15px;
					border-bottom: 1px solid $border_line;
					border-top: 1px solid $border_line;
					&:before {
						content: " ";
						display: table;
					}
					&:after {
						content: " ";
						display: table;
						clear: both;
					}
					a {
						color: $text_main !important;
					}
				}
				.user-footer {
					background-color: $background_light;
					padding: 10px;
					&:before {
						content: " ";
						display: table;
					}
					&:after {
						content: " ";
						display: table;
						clear: both;
					}
					.btn-default {
						color: $white;
					}
				}
			}
			> li.user-header {
				// height: 175px;
				padding: 10px;
				text-align: center;
				> img {
					z-index: 5;
					height: 90px;
					width: 90px;
					border: 3px solid;
					border-color: transparent;
					border-color: $white_20;
				}
				> p {
					z-index: 5;
					color: $text_black;
					font-size: 17px;
					margin-top: 10px;
					> small {
						display: block;
						font-size: 12px;
					}
				}
			}
		}
	}
	.notifications-menu > .dropdown-menu {
		width: 280px;
		padding: 0;
		margin: 0;
		top: 100%;
		> li {
			position: relative;
			&.header {
				@include border-radius(4px 4px 0 0);
				background-color: $white;
				padding: 7px 10px;
				border-bottom: 1px solid $border_line;
				color: $text_main;
				font-size: 14px;
			}
			.menu {
				max-height: 200px;
				margin: 0;
				padding: 0;
				list-style: none;
				overflow-x: hidden;
				> li > a {
					display: block;
					white-space: nowrap;
					border-bottom: 1px solid $border_line;
					color: $text_main;
					overflow: hidden;
					text-overflow: ellipsis;
					padding: 10px;
					&:hover {
						background: $border_line;
						text-decoration: none;
					}
					> {
						.glyphicon {
							width: 20px;
						}
						.fa {
							width: 20px;
						}
						.ion {
							width: 20px;
						}
					}
				}
			}
			&.footer > a {
				@include border-radius(0 0 4px 4px);
				font-size: 12px;
				background-color: $white;
				padding: 7px 10px;
				border-bottom: 1px solid $border_line;
				color: $text_main !important;
				text-align: center;
				&:hover {
					text-decoration: none;
					font-weight: normal;
				}
			}
		}
	}
	.messages-menu > .dropdown-menu {
		width: 280px;
		padding: 0;
		margin: 0;
		top: 100%;
		> li {
			position: relative;
			&.header {
				@include border-radius(4px 4px 0 0);
				background-color: $white;
				padding: 7px 10px;
				border-bottom: 1px solid $border_line;
				color: $text_main;
				font-size: 14px;
			}
			.menu {
				max-height: 200px;
				margin: 0;
				padding: 0;
				list-style: none;
				overflow-x: hidden;
				> li > a {
					display: block;
					white-space: nowrap;
					border-bottom: 1px solid $border_line;
					margin: 0;
					padding: 10px;
					&:hover {
						background: $border_line;
						text-decoration: none;
					}
					> h4 {
						padding: 0;
						margin: 0 0 0 45px;
						color: $text_main;
						font-size: 15px;
						position: relative;
						> small {
							color: $gray;
							font-size: 10px;
							position: absolute;
							top: 0;
							right: 0;
						}
					}
					> p {
						margin: 0 0 0 45px;
						font-size: 12px;
						color: $gray;
					}
					&:before {
						content: " ";
						display: table;
					}
					&:after {
						content: " ";
						display: table;
						clear: both;
					}
					> div > img {
						margin: auto 10px auto auto;
						width: 40px;
						height: 40px;
					}
				}
			}
			&.footer > a {
				@include border-radius(0 0 4px 4px);
				font-size: 12px;
				background-color: $white;
				padding: 7px 10px;
				border-bottom: 1px solid $border_line;
				color: $text_main !important;
				text-align: center;
				&:hover {
					text-decoration: none;
					font-weight: normal;
				}
			}
		}
	}
	.tasks-menu > .dropdown-menu {
		width: 280px;
		padding: 0;
		margin: 0;
		top: 100%;
		> li {
			position: relative;
			&.header {
				@include border-radius(4px 4px 0 0);
				background-color: $white;
				padding: 7px 10px;
				border-bottom: 1px solid $border_line;
				color: $text_main;
				font-size: 14px;
			}
			.menu {
				max-height: 200px;
				margin: 0;
				padding: 0;
				list-style: none;
				overflow-x: hidden;
				> li > a {
					display: block;
					white-space: nowrap;
					border-bottom: 1px solid $border_line;
					padding: 10px;
					&:hover {
						background: $border_line;
						text-decoration: none;
					}
					> h3 {
						font-size: 14px;
						padding: 0;
						margin: 0 0 10px 0;
						color: $gray;
					}
					> .progress {
						padding: 0;
						margin: 0;
					}
				}
			}
			&.footer > a {
				@include border-radius(0 0 4px 4px);
				font-size: 12px;
				background-color: $white;
				padding: 7px 10px;
				border-bottom: 1px solid $border_line;
				color: $text_main !important;
				text-align: center;
				&:hover {
					text-decoration: none;
					font-weight: normal;
				}
			}
		}
	}
}
.navbar-custom-menu > .navbar-nav > li {
	position: relative;
	display: inline-flex;
	margin: 0 15px;
	> .dropdown-menu {
		position: absolute;
		right: 0;
		left: auto;
	}
}
.nav > li > a {
	&:hover {
		color: $sidebar-text-color-light;
		background: $background_light;
	}
	&:active {
		color: $sidebar-text-color-light;
		background: $background_light;
	}
}
.nav-pills > li {
	> a {
		@include border-radius(0);
		border-top: 3px solid transparent;
		color: $text_main;
		> {
			.fa {
				margin-right: 5px;
			}
			.glyphicon {
				margin-right: 5px;
			}
			.ion {
				margin-right: 5px;
			}
		}
	}
	&.active > a {
		border-top-color: $btn_primary;
		font-weight: 600;
		&:hover {
			border-top-color: $btn_primary;
		}
		&:focus {
			border-top-color: $btn_primary;
		}
	}
}
.nav-stacked > li {
	> a {
		@include border-radius(0);
		border-top: 0;
		border-left: 3px solid transparent;
		color: $text_main;
	}
	&.header {
		border-bottom: 1px solid $border_line;
		color: $gray;
		margin-bottom: 10px;
		padding: 5px 10px;
		text-transform: uppercase;
	}
	&.active > a {
		background: transparent;
		color: $text_main;
		border-top: 0;
		border-left-color: $btn_primary;
		&:hover {
			background: transparent;
			color: $text_main;
			border-top: 0;
			border-left-color: $btn_primary;
		}
	}
}