.fc-media-screen .fc-timegrid-event {
    border-width: 2px;
    padding: 3px;
}
.fc-daygrid-day {
    .fc-daygrid-day-frame {
        display: none;
    }
    p {
        text-align: center;
        margin-bottom: 0;
    }
}
@include media-breakpoint-down(sm) {
    .fc-timeGridWeek-view {
        .fc-scrollgrid-sync-table {
            display: none;
        }
    }
    .fc .fc-toolbar {
        display: inline !important;
    }
    .fc-toolbar-chunk {
        width: 100%;
        text-align: center;
    }
}