@mixin box-shadow {
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
}

@mixin box-shadow-light {
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
}

@mixin underline($width, $fat){
    border-bottom: $fat solid $underline;
    width: unquote($width + "%");
}

@mixin gradient {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+25,000000+100&0+0,0.4+25,0.4+100 */
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 25%, rgba(0,0,0,0.3) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.2) 25%,rgba(0,0,0,0.3) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.2) 25%,rgba(0,0,0,0.3) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin title($h, $i){
    $hsize: 1em;
    @if $h == 1 { $hsize: 2em; }
    @if $h == 2 { $hsize: 1.5em; }
    @if $h == 3 { $hsize: 1.3em; }
    @if $h == 4 { $hsize: 1.1em; }
    font-size: $hsize;
    line-height: $hsize + 0.1em;
    text-shadow: 1px 2px rgba(50, 50, 50, 0.2);
    text-align: center;
    @if $i == true { font-style: italic; }
}

@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}
@mixin transition-property($property...) {
    -moz-transition-property:    $property;
    -o-transition-property:      $property;
    -webkit-transition-property: $property;
    transition-property:         $property;
}
@mixin transition-duration($duration...) {
    -moz-transition-property:    $duration;
    -o-transition-property:      $duration;
    -webkit-transition-property: $duration;
    transition-property:         $duration;
}
@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function:    $timing;
    -o-transition-timing-function:      $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
    -moz-transition-delay:    $delay;
    -o-transition-delay:      $delay;
    -webkit-transition-delay: $delay;
    transition-delay:         $delay;
}

// generic transform
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
    @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
    @include transform(scale($scale));
}
// translate
@mixin translate ($x, $y) {
    @include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
    -moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
    transform-origin: $origin;
}

@mixin position($position: relative, $top: null, $right: null, $bottom: null, $left: null, $z-index: null) {
    position: $position;
    @if $top {
        top: $top;
    }
    @if $right {
        right: $right;
    }
    @if $bottom {
        bottom: $bottom;
    }
    @if $left {
        left: $left;
    }
    @if $z-index {
        z-index: $z-index;
    }
}

@mixin background-image ($path) {
    -moz-background-image: url($path);
    -webkit-background-image: url($path);
    -o-background-image: url($path);
    background-image: url($path);
}

@mixin background-size ($size) {
    -moz-background-size: $size;
    -webkit-background-size: $size;
    -o-background-size: $size;
    background-size: $size;
}

@mixin border-radius ($percentage...) {
    -moz-border-radius: $percentage;
    -webkit-border-radius: $percentage;
    border-radius: $percentage;
}

@mixin checkout-logos ($image) {
    padding-left: 100px;
    background-image: url($img_url + $image);
    background-position: 20px;
    background-repeat: no-repeat;
    max-width: 100%;
}

@mixin home-title {
    text-align: center;
    color: $main-color;
    text-transform: uppercase;
    font-size: 3em;
    padding-bottom: $home-title-padding;
    margin: 0;
}

//@extend-elements
//original selectors
//.content-wrapper, .main-footer
%extend_1 {
	@include transition(transform 0.3s ease-in-out, margin 0.3s ease-in-out);
	z-index: 820;
	.sidebar-mini &{
		@media (min-width: 768px) {
			margin-left: 230px;
		}
	}
}