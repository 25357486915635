.cms-images {
    background-color: #fff;
    top: 150px;
    left: 150px;
    padding-bottom: 1px;
    .browse {
        display: block;
        border: none;
        background-color: $main-color;
        color: #fff;
        padding: 7px 20px;
        position: absolute;
        cursor: pointer;
    }
    .images {
        position: relative;
        height: 100px;
        padding: 15px;
        .form-group {
            width: 80%;
            float: left;
        }
        input {
            padding-left: 28px;
            padding-top: 0;
        }
        .images-save {
            margin-left: 15px;
            float: left;
            margin-top: 30px;
            font-size: 20px;
        }
    }
    .images-list {
        padding: 10px;
        margin: 10px;
        border: 1px solid #ccc;
        border-radius: 8px;
        &-inner {
            max-height: 400px;
            overflow-y: scroll;
            border: 1px solid #ccc;
            border-radius: 8px;
            display: inline-flex;
            width: 100%;
            height: 100%;
            .image-wrapper {
                position: relative;
                max-width: 100px;
                margin: 5px;
                .img-remove {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    color: $btn_error;
                    cursor: pointer;
                }
            }
            img {
                margin: 5px;
                float: left;
                // &:hover {
                //     opacity: 0.5;
                //     cursor: pointer;
                // }
            }
        }
    }
}