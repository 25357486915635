
@media(max-width: 767px) {
	.content-wrapper {
		margin-left: 0;
	}
	.main-footer {
		margin-left: 0;
	}
	.sidebar-open {
		.content-wrapper {
			@include transform(translate(0, 0));
		}
		.main-footer {
			@include transform(translate(0, 0));
		}
		.main-sidebar {
			@include transform(translate(0, 0));
		}
	}
	.fixed {
		.content-wrapper {
			padding-top: 100px;
		}
		.right-side {
			padding-top: 100px;
		}
	}
	.main-header {
		position: relative;
		.navbar-right {
			float: none;
			> li {
				color: inherit;
				border: 0;
			}
		}
		.logo {
			width: 100% !important;
			float: none !important;
		}
		.navbar {
			width: 100%;
			float: none;
			margin: 0;
			padding: 0;
		}
		.navbar-custom-menu {
			float: right;
		}
	}
	.main-sidebar {
		@include transform(translate(-230px, 0));
		li {
			&.header {
				padding: 5px 25px 5px 15px;
				font-size: 1.25rem;

			}
			a {
				padding: 5px 5px 5px 15px;
			}
		}
	}
	.control-sidebar {
		padding-top: 100px;
	}
	.small-box {
		text-align: center;
		.icon {
			display: none;
		}
		p {
			font-size: 12px;
		}
	}
	.navbar-collapse .main-header .navbar-right {
		margin: 7.5px -15px;
	}
	.navbar-nav > .user-menu .user-image {
		float: none;
		margin-right: 0;
		margin-top: -8px;
		line-height: 10px;
	}
	.h4, h4 {
		font-size: 1.25rem;
	}
}
@media(min-width: 768px) {
	.sidebar-collapse {
		.content-wrapper {
			margin-left: 0;
		}
		.main-footer {
			margin-left: 0;
		}
		.main-sidebar {
			@include transform(translate(-230px, 0));
		}
	}
	.sidebar-mini {
		&.sidebar-collapse {
			.content-wrapper {
				margin-left: 50px !important;
				z-index: 840;
			}
			.right-side {
				margin-left: 50px !important;
				z-index: 840;
			}
			.main-footer {
				margin-left: 50px !important;
				z-index: 840;
			}
			.main-sidebar {
				@include transform(translate(0, 0));
				width: 50px !important;
				z-index: 850;
				.user-panel > .info {
					display: none !important;
				}
			}
			.sidebar-menu {
				> li {
					position: relative;
					> a {
						margin-right: 0;
						> span {
							border-top-right-radius: 4px;
							display: none !important;
						}
						> .pull-right {
							display: none !important;
						}
					}
					&:not(.treeview) > a > span {
						border-bottom-right-radius: 4px;
					}
					> .treeview-menu {
						padding-top: 5px;
						padding-bottom: 5px;
						border-bottom-right-radius: 4px;
						display: none !important;
					}
				}
				li.header {
					display: none !important;
				}
			}
			.sidebar-form {
				display: none !important;
			}
			.main-header {
				.logo {
					width: 50px;
					> {
						.logo-mini {
							display: block;
							margin-left: -15px;
							margin-right: -15px;
							font-size: 18px;
						}
						.logo-lg {
							display: none;
						}
					}
				}
				.navbar {
					margin-left: 50px;
				}
			}
		}
		&:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu > li:hover {
			> a {
				> span {
					top: 0;
					margin-left: -3px;
					padding: 12px 5px 12px 20px;
					background-color: $sidebar-background-color;
					&:not(.pull-right) {
						display: block !important;
						position: absolute;
						width: 180px;
						left: 50px;
					}
				}
				> .pull-right-container {
					position: relative !important;
					float: right;
					width: auto !important;
					left: 180px !important;
					top: -22px !important;
					z-index: 900;
					> .label:not(:first-of-type) {
						display: none;
					}
				}
			}
			> .treeview-menu {
				display: block !important;
				position: absolute;
				width: 180px;
				left: 50px;
				top: 44px;
				margin-left: 0;
			}
		}
	}
	.control-sidebar-open {
		.content-wrapper {
			margin-right: 230px;
		}
		.right-side {
			margin-right: 230px;
		}
		.main-footer {
			margin-right: 230px;
		}
	}
	.modal-content {
		@include box-shadow(0 2px 3px $black_125);
	}
}
@media(max-width: 991px) {
	.main-header {
		.navbar-custom-menu a {
			color: $sidebar-text-color-light;
			background: transparent;
		}
		.navbar-right a {
			color: $sidebar-text-color-light;
			background: transparent;
		}
	}
	.navbar-custom-menu {
		.navbar-nav {
			margin: 0;
			float: left;
			> li {
				float: left;
				> a {
					padding-top: 15px;
					padding-bottom: 15px;
					line-height: 20px;
				}
			}
		}
		> .navbar-nav {
			float: right;
			> li {
				position: static;
				> .dropdown-menu {
					position: absolute;
					right: 5%;
					left: auto;
					border: 1px solid $border_line;
					background: $white;
				}
			}
		}
	}
	.error-page {
		width: 100%;
		> {
			.headline {
				float: none;
				text-align: center;
			}
			.error-content {
				margin-left: 0;
				> h3 {
					text-align: center;
				}
			}
		}
	}
	.content-header > .breadcrumb {
		position: relative;
		margin-top: 5px;
		top: 0;
		right: 0;
		float: none;
		background: $border_line;
		padding-left: 10px;
		li:before {
			color: $text_gray;
		}
	}
	.navbar-collapse.pull-left {
		float: none !important;
		+ .navbar-custom-menu {
			display: block;
			position: absolute;
			top: 0;
			right: 40px;
		}
	}
	.navbar-nav > {
		.notifications-menu > .dropdown-menu > li.footer > a {
			background: $white !important;
			color: $text_main !important;
		}
		.messages-menu > .dropdown-menu > li.footer > a {
			background: $white !important;
			color: $text_main !important;
		}
		.tasks-menu > .dropdown-menu > li.footer > a {
			background: $white !important;
			color: $text_main !important;
		}
		.user-menu > .dropdown-menu > {
			.user-body a {
				background: $white !important;
				color: $text_main !important;
			}
			.user-footer .btn-default:hover {
				background-color: $background_light;
			}
		}
	}
	.chart-legend > li {
		float: left;
		margin-right: 10px;
	}
}
@media print {
	.no-print {
		display: none !important;
	}
	.main-sidebar {
		display: none !important;
	}
	.left-side {
		display: none !important;
	}
	.main-header {
		display: none !important;
	}
	.content-header {
		display: none !important;
	}
	.content-wrapper {
		margin-left: 0 !important;
		min-height: 0 !important;
		@include transform(translate(0, 0) !important);
	}
	.right-side {
		margin-left: 0 !important;
		min-height: 0 !important;
		@include transform(translate(0, 0) !important);
	}
	.main-footer {
		margin-left: 0 !important;
		min-height: 0 !important;
		@include transform(translate(0, 0) !important);
	}
	.fixed {
		.content-wrapper {
			padding-top: 0 !important;
		}
		.right-side {
			padding-top: 0 !important;
		}
	}
	.invoice {
		width: 100%;
		border: 0;
		margin: 0;
		padding: 0;
	}
	.invoice-col {
		float: left;
		width: 33.3333333%;
	}
	.table-responsive {
		overflow: auto;
		> .table tr {
			th {
				white-space: normal !important;
			}
			td {
				white-space: normal !important;
			}
		}
	}
}