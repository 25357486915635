/*
 * Table styles
 */

 table {
    &.dataTable {
      width: 100%;
      margin: 0 auto;
      clear: both;
      border-collapse: separate;
      border-spacing: 0;
  
      /*
       * Header and footer styles
       */
      /*
       * Body styles
       */
  
      tr {
        a, i {
          cursor: pointer;
        }
      }
    }
  
    .filter {
      width: calc(100% - 15px);
      height: 25px;
      display: block;
    }
  
    &.dataTable {
      thead th, tfoot th {
        font-weight: bold;
      }
  
      thead {
        th, td {
          padding: 10px 18px;
          border-bottom: 1px solid #111;
        }
  
        th:active, td:active {
          outline: none;
        }
      }
  
      tfoot {
        th, td {
          padding: 10px 18px 6px 18px;
          border-top: 1px solid #111;
        }
      }
  
      thead {
        .sorting, .sorting_asc, .sorting_desc, .sorting_asc_disabled, .sorting_desc_disabled {
          cursor: pointer;
          *cursor: hand;
          background-repeat: no-repeat;
          background-position: center right;
        }
      }
  
      tbody {
        tr {
          background-color: #ffffff;
  
          &.selected {
            background-color: #B0BED9;
          }
        }
  
        th, td {
          padding: 6px 10px;
          line-height: 30px;
        }
      }
  
      &.row-border tbody {
        th, td {
          border-top: 1px solid #ddd;
        }
      }
  
      &.display tbody {
        th, td {
          border-top: 1px solid #ddd;
        }
      }
  
      &.row-border tbody tr:first-child {
        th, td {
          border-top: none;
        }
      }
  
      &.display tbody tr:first-child {
        th, td {
          border-top: none;
        }
      }
  
      &.cell-border tbody {
        th, td {
          border-top: 1px solid #ddd;
          border-right: 1px solid #ddd;
        }
  
        tr {
          th:first-child, td:first-child {
            border-left: 1px solid #ddd;
          }
  
          &:first-child {
            th, td {
              border-top: none;
            }
          }
        }
      }
  
      &.stripe tbody tr.odd, &.display tbody tr.odd {
        background-color: #f9f9f9;
      }
  
      &.stripe tbody tr.odd.selected, &.display tbody tr.odd.selected {
        background-color: #acbad4;
      }
  
      &.hover tbody tr:hover, &.display tbody tr:hover {
        background-color: #f6f6f6;
      }
  
      &.hover tbody tr:hover.selected, &.display tbody tr:hover.selected {
        background-color: #aab7d1;
      }
  
      &.order-column tbody tr > {
        .sorting_1, .sorting_2, .sorting_3 {
          background-color: #fafafa;
        }
      }
  
      &.display tbody tr > {
        .sorting_1, .sorting_2, .sorting_3 {
          background-color: #fafafa;
        }
      }
  
      &.order-column tbody tr.selected > {
        .sorting_1, .sorting_2, .sorting_3 {
          background-color: #acbad5;
        }
      }
  
      &.display tbody tr {
        &.selected > {
          .sorting_1, .sorting_2, .sorting_3 {
            background-color: #acbad5;
          }
        }
  
        &.odd > .sorting_1 {
          background-color: #f1f1f1;
        }
      }
  
      &.order-column.stripe tbody tr.odd > .sorting_1 {
        background-color: #f1f1f1;
      }
  
      &.display tbody tr.odd > .sorting_2, &.order-column.stripe tbody tr.odd > .sorting_2 {
        background-color: #f3f3f3;
      }
  
      &.display tbody tr.odd > .sorting_3, &.order-column.stripe tbody tr.odd > .sorting_3 {
        background-color: whitesmoke;
      }
  
      &.display tbody tr.odd.selected > .sorting_1, &.order-column.stripe tbody tr.odd.selected > .sorting_1 {
        background-color: #a6b4cd;
      }
  
      &.display tbody tr.odd.selected > .sorting_2, &.order-column.stripe tbody tr.odd.selected > .sorting_2 {
        background-color: #a8b5cf;
      }
  
      &.display tbody tr.odd.selected > .sorting_3, &.order-column.stripe tbody tr.odd.selected > .sorting_3 {
        background-color: #a9b7d1;
      }
  
      &.display tbody tr.even > .sorting_1, &.order-column.stripe tbody tr.even > .sorting_1 {
        background-color: #fafafa;
      }
  
      &.display tbody tr.even > .sorting_2, &.order-column.stripe tbody tr.even > .sorting_2 {
        background-color: #fcfcfc;
      }
  
      &.display tbody tr.even > .sorting_3, &.order-column.stripe tbody tr.even > .sorting_3 {
        background-color: #fefefe;
      }
  
      &.display tbody tr.even.selected > .sorting_1, &.order-column.stripe tbody tr.even.selected > .sorting_1 {
        background-color: #acbad5;
      }
  
      &.display tbody tr.even.selected > .sorting_2, &.order-column.stripe tbody tr.even.selected > .sorting_2 {
        background-color: #aebcd6;
      }
  
      &.display tbody tr.even.selected > .sorting_3, &.order-column.stripe tbody tr.even.selected > .sorting_3 {
        background-color: #afbdd8;
      }
  
      &.display tbody tr:hover > .sorting_1, &.order-column.hover tbody tr:hover > .sorting_1 {
        background-color: #eaeaea;
      }
  
      &.display tbody tr:hover > .sorting_2, &.order-column.hover tbody tr:hover > .sorting_2 {
        background-color: #ececec;
      }
  
      &.display tbody tr:hover > .sorting_3, &.order-column.hover tbody tr:hover > .sorting_3 {
        background-color: #efefef;
      }
  
      &.display tbody tr:hover.selected > .sorting_1, &.order-column.hover tbody tr:hover.selected > .sorting_1 {
        background-color: #a2aec7;
      }
  
      &.display tbody tr:hover.selected > .sorting_2, &.order-column.hover tbody tr:hover.selected > .sorting_2 {
        background-color: #a3b0c9;
      }
  
      &.display tbody tr:hover.selected > .sorting_3, &.order-column.hover tbody tr:hover.selected > .sorting_3 {
        background-color: #a5b2cb;
      }
  
      &.no-footer {
        border-bottom: 1px solid #111;
      }
  
      &.nowrap {
        th, td {
          white-space: nowrap;
        }
      }
  
      &.compact {
        thead {
          th, td {
            padding: 4px 17px 4px 4px;
          }
        }
  
        tfoot {
          th, td {
            padding: 4px;
          }
        }
  
        tbody {
          th, td {
            padding: 4px;
          }
        }
      }
  
      th.dt-left, td.dt-left {
        text-align: left;
      }
  
      th.dt-center {
        text-align: center;
      }
  
      td {
        &.dt-center, &.dataTables_empty {
          text-align: center;
        }
      }
  
      th.dt-right, td.dt-right {
        text-align: right;
      }
  
      th.dt-justify, td.dt-justify {
        text-align: justify;
      }
  
      th.dt-nowrap, td.dt-nowrap {
        white-space: nowrap;
      }
  
      thead {
        th.dt-head-left, td.dt-head-left {
          text-align: left;
        }
      }
  
      tfoot {
        th.dt-head-left, td.dt-head-left {
          text-align: left;
        }
      }
  
      thead {
        th.dt-head-center, td.dt-head-center {
          text-align: center;
        }
      }
  
      tfoot {
        th.dt-head-center, td.dt-head-center {
          text-align: center;
        }
      }
  
      thead {
        th.dt-head-right, td.dt-head-right {
          text-align: right;
        }
      }
  
      tfoot {
        th.dt-head-right, td.dt-head-right {
          text-align: right;
        }
      }
  
      thead {
        th.dt-head-justify, td.dt-head-justify {
          text-align: justify;
        }
      }
  
      tfoot {
        th.dt-head-justify, td.dt-head-justify {
          text-align: justify;
        }
      }
  
      thead {
        th.dt-head-nowrap, td.dt-head-nowrap {
          white-space: nowrap;
        }
      }
  
      tfoot {
        th.dt-head-nowrap, td.dt-head-nowrap {
          white-space: nowrap;
        }
      }
  
      tbody {
        th.dt-body-left, td.dt-body-left {
          text-align: left;
        }
  
        th.dt-body-center, td.dt-body-center {
          text-align: center;
        }
  
        th.dt-body-right, td.dt-body-right {
          text-align: right;
        }
  
        th.dt-body-justify, td.dt-body-justify {
          text-align: justify;
        }
  
        th.dt-body-nowrap, td.dt-body-nowrap {
          white-space: nowrap;
        }
      }
  
      box-sizing: content-box;
  
      th, td {
        box-sizing: content-box;
      }
    }
  }
  
  // table.dataTable thead .sorting {
  //     background-image: url("../images/sort_both.png");
  // }
  
  // table.dataTable thead .sorting_asc {
  //     background-image: url("../images/sort_asc.png");
  // }
  
  // table.dataTable thead .sorting_desc {
  //     background-image: url("../images/sort_desc.png");
  // }
  
  // table.dataTable thead .sorting_asc_disabled {
  //     background-image: url("../images/sort_asc_disabled.png");
  // }
  
  // table.dataTable thead .sorting_desc_disabled {
  //     background-image: url("../images/sort_desc_disabled.png");
  // }
  
  /*
     * Control feature layout
     */
  
  .dataTables_wrapper {
    position: relative;
    clear: both;
    *zoom: 1;
    zoom: 1;
  
    .dataTables_length {
      float: left;
    }
  
    .dataTables_filter {
      float: right;
      text-align: right;
  
      input {
        margin-left: 0.5em;
      }
    }
  
    .dataTables_info {
      clear: both;
      float: left;
      padding-top: 0.755em;
    }
  
    .dataTables_paginate {
      float: right;
      text-align: right;
      padding-top: 0.25em;
  
      .paginate_button {
        box-sizing: border-box;
        display: inline-block;
        min-width: 1.5em;
  
        // padding: 0.5em 1em;
        margin-left: 2px;
        text-align: center;
        text-decoration: none !important;
        cursor: pointer;
        *cursor: hand;
        color: #333 !important;
  
        // border: 1px solid transparent;
        border-radius: 2px;
  
        &.current {
          color: #333 !important;
          border: 1px solid #979797;
          background-color: white;
          background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, white), color-stop(100%, #dcdcdc));
  
          /* Chrome,Safari4+ */
          background: -webkit-linear-gradient(top, white 0%, #dcdcdc 100%);
  
          /* Chrome10+,Safari5.1+ */
          background: -moz-linear-gradient(top, white 0%, #dcdcdc 100%);
  
          /* FF3.6+ */
          background: -ms-linear-gradient(top, white 0%, #dcdcdc 100%);
  
          /* IE10+ */
          background: -o-linear-gradient(top, white 0%, #dcdcdc 100%);
  
          /* Opera 11.10+ */
          background: linear-gradient(to bottom, white 0%, #dcdcdc 100%);
  
          /* W3C */
  
          &:hover {
            color: #333 !important;
            border: 1px solid #979797;
            background-color: white;
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, white), color-stop(100%, #dcdcdc));
  
            /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(top, white 0%, #dcdcdc 100%);
  
            /* Chrome10+,Safari5.1+ */
            background: -moz-linear-gradient(top, white 0%, #dcdcdc 100%);
  
            /* FF3.6+ */
            background: -ms-linear-gradient(top, white 0%, #dcdcdc 100%);
  
            /* IE10+ */
            background: -o-linear-gradient(top, white 0%, #dcdcdc 100%);
  
            /* Opera 11.10+ */
            background: linear-gradient(to bottom, white 0%, #dcdcdc 100%);
  
            /* W3C */
          }
        }
  
        &.disabled {
          cursor: default;
          color: #666 !important;
          border: 1px solid transparent;
          background: transparent;
          box-shadow: none;
  
          &:hover, &:active {
            cursor: default;
            color: #666 !important;
            border: 1px solid transparent;
            background: transparent;
            box-shadow: none;
          }
        }
  
        &:hover {
          color: white !important;
  
          // border: 1px solid #111;
          // background-color: #585858;
          // background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111));
          // /* Chrome,Safari4+
          // background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
          // /* Chrome10+,Safari5.1+
          // background: -moz-linear-gradient(top, #585858 0%, #111 100%);
          // /* FF3.6+
          // background: -ms-linear-gradient(top, #585858 0%, #111 100%);
          // /* IE10+
          // background: -o-linear-gradient(top, #585858 0%, #111 100%);
          // /* Opera 11.10+
          // background: linear-gradient(to bottom, #585858 0%, #111 100%);
          // /* W3C */
       
        }
  
        &:active {
          outline: none;
          background-color: #2b2b2b;
  
          // background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2b2b2b), color-stop(100%, #0c0c0c));
          // /* Chrome,Safari4+
          // background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
          // /* Chrome10+,Safari5.1+
          // background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
          // /* FF3.6+
          // background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
          // /* IE10+
          // background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
          // /* Opera 11.10+
          // background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
          // /* W3C */
          box-shadow: inset 0 0 3px #111;
        }
      }
  
      .ellipsis {
        padding: 0 1em;
      }
    }
  
    .dataTables_processing {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 40px;
      margin-left: -50%;
      margin-top: -25px;
      padding-top: 20px;
      text-align: center;
      font-size: 1.2em;
      background-color: white;
      background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
      background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
      background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
      background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    }
  
    .dataTables_length, .dataTables_filter, .dataTables_info, .dataTables_processing, .dataTables_paginate {
      color: #333;
    }
  
    .dataTables_scroll {
      clear: both;
  
      div.dataTables_scrollBody {
        *margin-top: -1px;
        -webkit-overflow-scrolling: touch;
  
        > table > {
          thead > tr > {
            th, td {
              vertical-align: middle;
            }
          }
  
          tbody > tr > {
            th, td {
              vertical-align: middle;
            }
          }
  
          thead > tr > {
            th > div.dataTables_sizing, td > div.dataTables_sizing {
              height: 0;
              overflow: hidden;
              margin: 0 !important;
              padding: 0 !important;
            }
          }
  
          tbody > tr > {
            th > div.dataTables_sizing, td > div.dataTables_sizing {
              height: 0;
              overflow: hidden;
              margin: 0 !important;
              padding: 0 !important;
            }
          }
        }
      }
    }
  
    &.no-footer {
      .dataTables_scrollBody {
        border-bottom: 1px solid #111;
      }
  
      div {
        &.dataTables_scrollHead table.dataTable, &.dataTables_scrollBody > table {
          border-bottom: none;
        }
      }
    }
  
    &:after {
      visibility: hidden;
      display: block;
      content: "";
      clear: both;
      height: 0;
    }
  }
  
  @media screen and (max-width: 767px) {
    .dataTables_wrapper {
      .dataTables_info {
        float: none;
        text-align: center;
      }
  
      .dataTables_paginate {
        float: none;
        text-align: center;
        margin-top: 0.5em;
      }
    }
  }
  
  @media screen and (max-width: 640px) {
    .dataTables_wrapper {
      .dataTables_length {
        float: none;
        text-align: center;
      }
  
      .dataTables_filter {
        float: none;
        text-align: center;
        margin-top: 0.5em;
      }
    }
  }
  
  table.dataTable {
    clear: both;
    margin-top: 6px !important;
    margin-bottom: 6px !important;
    max-width: none !important;
    border-collapse: separate !important;
    border-spacing: 0;
  
    td, th {
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
    }
  
    td.dataTables_empty, th.dataTables_empty {
      text-align: center;
    }
  
    &.nowrap {
      th, td {
        white-space: nowrap;
      }
    }
  }
  
  div.dataTables_wrapper div {
    &.dataTables_length {
      label {
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
      }
  
      select {
        width: auto;
        display: inline-block;
      }
    }
  
    &.dataTables_filter {
      text-align: right;
  
      label {
        font-weight: normal;
        white-space: nowrap;
        text-align: left;
      }
  
      input {
        margin-left: 0.5em;
        display: inline-block;
        width: auto;
      }
    }
  
    &.dataTables_info {
      padding-top: 0.85em;
      white-space: nowrap;
    }
  
    &.dataTables_paginate {
      margin: 0;
      white-space: nowrap;
      text-align: right;
  
      ul.pagination {
        margin: 2px 0;
        white-space: nowrap;
        justify-content: flex-end;
      }
    }
  
    &.dataTables_processing {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200px;
      margin-left: -100px;
      margin-top: -26px;
      text-align: center;
      padding: 1em 0;
    }
  }
  
  table.dataTable thead {
    > tr > {
      th {
        &.sorting_asc, &.sorting_desc, &.sorting {
          padding-right: 30px;
        }
      }
  
      td {
        &.sorting_asc, &.sorting_desc, &.sorting {
          padding-right: 30px;
        }
      }
  
      th:active, td:active {
        outline: none;
      }
    }
  
    .sorting, .sorting_asc, .sorting_desc, .sorting_asc_disabled, .sorting_desc_disabled {
      cursor: pointer;
      position: relative;
    }
  
    .sorting {
      &:before, &:after {
        position: absolute;
        bottom: 0.9em;
        display: block;
        opacity: 0.3;
      }
    }
  
    .sorting_asc {
      &:before, &:after {
        position: absolute;
        bottom: 0.9em;
        display: block;
        opacity: 0.3;
      }
    }
  
    .sorting_desc {
      &:before, &:after {
        position: absolute;
        bottom: 0.9em;
        display: block;
        opacity: 0.3;
      }
    }
  
    .sorting_asc_disabled {
      &:before, &:after {
        position: absolute;
        bottom: 0.9em;
        display: block;
        opacity: 0.3;
      }
    }
  
    .sorting_desc_disabled {
      &:before, &:after {
        position: absolute;
        bottom: 0.9em;
        display: block;
        opacity: 0.3;
      }
    }
  
    .sorting:before, .sorting_asc:before, .sorting_desc:before, .sorting_asc_disabled:before, .sorting_desc_disabled:before {
      right: 1em;
      content: "\2191";
    }
  
    .sorting:after, .sorting_asc:after, .sorting_desc:after, .sorting_asc_disabled:after, .sorting_desc_disabled:after {
      right: 0.5em;
      content: "\2193";
    }
  
    .sorting_asc:before, .sorting_desc:after {
      opacity: 1;
    }
  
    .sorting_asc_disabled:before, .sorting_desc_disabled:after {
      opacity: 0;
    }
  }
  
  div {
    &.dataTables_scrollHead table.dataTable {
      margin-bottom: 0 !important;
    }
  
    &.dataTables_scrollBody table {
      border-top: none;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
  
      thead {
        .sorting:before, .sorting_asc:before, .sorting_desc:before, .sorting:after, .sorting_asc:after, .sorting_desc:after {
          display: none;
        }
      }
  
      tbody tr:first-child {
        th, td {
          border-top: none;
        }
      }
    }
  
    &.dataTables_scrollFoot > .dataTables_scrollFootInner {
      box-sizing: content-box;
  
      > table {
        margin-top: 0 !important;
        border-top: none;
      }
    }
  }
  
  @media screen and (max-width: 767px) {
    div.dataTables_wrapper div {
      &.dataTables_length, &.dataTables_filter, &.dataTables_info, &.dataTables_paginate {
        text-align: center;
      }
    }
  }
  
  table {
    &.dataTable.table-sm {
      > thead > tr > th {
        padding-right: 20px;
      }
  
      .sorting:before, .sorting_asc:before, .sorting_desc:before {
        top: 5px;
        right: 0.85em;
      }
  
      .sorting:after, .sorting_asc:after, .sorting_desc:after {
        top: 5px;
      }
    }
  
    &.table-bordered.dataTable {
      th, td {
        border-left-width: 0;
      }
  
      th:last-child, td:last-child {
        border-right-width: 0;
      }
  
      tbody {
        th, td {
          border-bottom-width: 0;
        }
      }
    }
  }
  
  div {
    &.dataTables_scrollHead table.table-bordered {
      border-bottom-width: 0;
    }
  
    &.table-responsive > div.dataTables_wrapper > div.row {
      margin: 0;
  
      > div[class^="col-"] {
        &:first-child {
          padding-left: 0;
        }
  
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  
  table.dataTable {
    &.dtr-inline.collapsed {
      > tbody > tr {
        > {
          td.child, th.child {
            cursor: default !important;
          }
  
          td {
            &.dataTables_empty {
              cursor: default !important;
            }
  
            &.child:before {
              display: none !important;
            }
          }
  
          th.child:before, td.dataTables_empty:before {
            display: none !important;
          }
        }
  
        &[role="row"] > {
          td:first-child, th:first-child {
            position: relative;
            padding-left: 30px;
            cursor: pointer;
          }
  
          td:first-child:before, th:first-child:before {
            top: 12px;
            left: 4px;
            height: 14px;
            width: 14px;
            display: block;
            position: absolute;
            color: white;
            border: 2px solid white;
            border-radius: 3px;
            box-shadow: 0 0 3px #444;
            box-sizing: content-box;
            text-align: center;
            text-indent: 0 !important;
            font-family: 'Courier New', Courier, monospace;
            line-height: 14px;
            content: '+';
            background-color: $main_color;
          }
        }

        &.parent > {
          td:first-child:before, th:first-child:before {
            content: '-';
            background-color: $btn_error_light;
          }
        }
      }
  
      &.compact > tbody > tr > {
        td:first-child, th:first-child {
          padding-left: 27px;
        }
  
        td:first-child:before, th:first-child:before {
          top: 5px;
          left: 4px;
          height: 14px;
          width: 14px;
          border-radius: 14px;
          line-height: 14px;
          text-indent: 3px;
        }
      }
    }
  
    &.dtr-column > tbody > tr {
      > {
        td.control, th.control {
          position: relative;
          cursor: pointer;
        }
  
        td.control:before, th.control:before {
          top: 50%;
          left: 50%;
          height: 16px;
          width: 16px;
          margin-top: -10px;
          margin-left: -10px;
          display: block;
          position: absolute;
          color: white;
          border: 2px solid white;
          border-radius: 14px;
          box-shadow: 0 0 3px #444;
          box-sizing: content-box;
          text-align: center;
          text-indent: 0 !important;
          font-family: 'Courier New', Courier, monospace;
          line-height: 14px;
          content: '+';
          background-color: #0275d8;
        }
      }
  
      &.parent {
        td.control:before, th.control:before {
          content: '-';
          background-color: #d33333;
        }
      }
    }
  
    > tbody > tr.child {
      padding: 0.5em 1em;
  
      &:hover {
        background: transparent !important;
      }
  
      ul.dtr-details {
        display: inline-block;
        list-style-type: none;
        margin: 0;
        padding: 0;
  
        > li {
          border-bottom: 1px solid #efefef;
          padding: 0.5em 0;
  
          &:first-child {
            padding-top: 0;
          }
  
          &:last-child {
            border-bottom: none;
          }
        }
      }
  
      span.dtr-title {
        display: inline-block;
        min-width: 75px;
        font-weight: bold;
      }
    }
  }
  
  div {
    &.dtr-modal {
      position: fixed;
      box-sizing: border-box;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 100;
      padding: 10em 1em;
  
      div {
        &.dtr-modal-display {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 50%;
          height: 50%;
          overflow: auto;
          margin: auto;
          z-index: 102;
          overflow: auto;
          background-color: #f5f5f7;
          border: 1px solid black;
          border-radius: 0.5em;
          box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6);
        }
  
        &.dtr-modal-content {
          position: relative;
          padding: 1em;
        }
  
        &.dtr-modal-close {
          position: absolute;
          top: 6px;
          right: 6px;
          width: 22px;
          height: 22px;
          border: 1px solid #eaeaea;
          background-color: #f9f9f9;
          text-align: center;
          border-radius: 3px;
          cursor: pointer;
          z-index: 12;
  
          &:hover {
            background-color: #eaeaea;
          }
        }
  
        &.dtr-modal-background {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 101;
          background: rgba(0, 0, 0, 0.6);
        }
      }
    }
  
    &.dtr-bs-modal table.table tr:first-child td {
      border-top: none;
    }
  }
  
  @media screen and (max-width: 767px) {
    div.dtr-modal div.dtr-modal-display {
      width: 95%;
    }
  }