.form-wrapper {
    section {
        border-bottom: 1px solid $light-gray;
        margin-bottom: 15px;
    }
}

.parts {
    .part-button-wrapper {
        #add-part {
            cursor: pointer;
            float: right;
            clear: right;
        }
        .add-part-note {
            float: left;
        }
        @media (max-width: 767px) {
            position: fixed;
            bottom: 0;
            z-index: 9999;
            width: 100%;
            left: 0;
            padding-right: 20px;
            padding-bottom: 20px;
            .add-part-note {
                display: none;
            }
        }
    }
    .table {
        tr {
            position: relative;
        }
        &-head-hint {
            position: absolute;
            visibility: hidden;
        }
    }
}