.draggable-modal {
    margin-right: 20px;
}
.draggable-container {
    position: relative;
    z-index: 2000;

}
.draggable-wrapper {
    max-width: 500px;
    position: absolute;
    @include media-breakpoint-down(xs) {
        top: 0 !important;
        right: 0 !important;
        left: 0 !important;
        height: 100%;
        max-width: calc(100vw - 30px);
    }
    @include media-breakpoint-up(xs) {
        top: 50px;
        right: 50px;
        width: 500px;
    }
    z-index: 9999;
    border: 1px solid #eee;
    // height: 400px;
    background-color: $white;
    @include transition(300ms);
    transition-property: height, width;
    display: none;
    &.active {
        display: block;
    }
    &.fullscreen {
        max-width: 100%;
        width: 100%;
        height: 100%;
        top: 0 !important;
        left: 0 !important;
        right: 0;
        @include transition(300ms);
        transition-property: height, width;

        .draggable-content {
            height: 0 !important;
            overflow: hidden;
        }
    }
    &.collapsed-box {
        height: 0;
    }
    &.move-1 {
        left: 20px;
        top: 20px
    }
    &.move-2 {
        left: 40px;
        top: 40px
    }
    &.move-3 {
        left: 60px;
        top: 60px
    }
    &.move-4 {
        left: 80px;
        top: 80px
    }

    .draggable-header {
        background-color: $main_color_light;
        color: $white;
    }

    .draggable-content {
        overflow-y: scroll;
        // height: 335px;
        background-color: $white;
        margin: 0px;
    }
}
.delete-modal {
    z-index: 9999;
}