.modal {
	background: $black_30;
	.panel-body {
		color: $text_main;
	}
}
.modal-content {
	@include border-radius(0);
	@include box-shadow(0 2px 3px $black_125);
	border: 0;
}
.modal-header {
	border-bottom-color: $border_line;
}
.modal-footer {
	border-top-color: $border_line;
}
.modal-primary {
	.modal-header {
		border-color: $btn_primary_modal;
		color: $white !important;
		background-color: $btn_primary !important;
	}
	.modal-footer {
		border-color: $btn_primary_modal;
		color: $white !important;
		background-color: $btn_primary !important;
	}
	.modal-body {
		color: $white !important;
		background-color: $btn_primary !important;
	}
}
.modal-warning {
	.modal-header {
		border-color: $btn_warning_modal;
		color: $white !important;
		background-color: $btn_warning_light !important;
	}
	.modal-footer {
		border-color: $btn_warning_modal;
		color: $white !important;
		background-color: $btn_warning_light !important;
	}
	.modal-body {
		color: $white !important;
		background-color: $btn_warning !important;
	}
}
.modal-info {
	.modal-header {
		border-color: $btn_info_modal;
		color: $white !important;
		background-color: $btn_info_light !important;
	}
	.modal-footer {
		border-color: $btn_info_modal;
		color: $white !important;
		background-color: $btn_info_light !important;
	}
	.modal-body {
		color: $white !important;
		background-color: $btn_info !important;
	}
}
.modal-success {
	.modal-header {
		border-color: $btn_success_modal;
		color: $white !important;
		background-color: $btn_success !important;
	}
	.modal-footer {
		border-color: $btn_success_modal;
		color: $white !important;
		background-color: $btn_success !important;
	}
	.modal-body {
		color: $white !important;
		background-color: $btn_success_light !important;
	}
}
.modal-danger {
	.modal-header {
		border-color: $btn_error_modal;
		color: $white !important;
		background-color: $btn_error_muted !important;
	}
	.modal-footer {
		border-color: $btn_error_modal;
		color: $white !important;
		background-color: $btn_error_muted !important;
	}
	.modal-body {
		color: $white !important;
		background-color: $btn_error !important;
	}
}