#footer {
    height: 50px;
    text-align: center;
}
.login-page {
    #footer {
        height: 50px;
        text-align: center;
        position: absolute;
        width: 100%;
        bottom: 0;
        background-color: #fff;
    }
}