.progress-description {
	display: block;
	font-size: 14px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0;
}
.progress {
	@include box-shadow(none);
	@include border-radius(1px);
	.progress-bar {
		@include border-radius(1px);
	}
	&.sm {
		height: 10px;
		@include border-radius(1px);
		.progress-bar {
			@include border-radius(1px);
		}
	}
	&.xs {
		height: 7px;
		@include border-radius(1px);
		.progress-bar {
			@include border-radius(1px);
		}
	}
	&.xxs {
		height: 3px;
		@include border-radius(1px);
		.progress-bar {
			@include border-radius(1px);
		}
	}
	&.vertical {
		position: relative;
		width: 30px;
		height: 200px;
		display: inline-block;
		margin-right: 10px;
		&.sm {
			width: 20px;
		}
		&.progress-sm {
			width: 20px;
		}
		&.xs {
			width: 10px;
		}
		&.progress-xs {
			width: 10px;
		}
		&.xxs {
			width: 3px;
		}
		&.progress-xxs {
			width: 3px;
		}
		> .progress-bar {
			width: 100%;
			position: absolute;
			bottom: 0;
		}
	}
	> .progress-bar {
		@include box-shadow(none);
		@include border-radius(1px);
		.progress-bar {
			@include border-radius(1px);
		}
	}
}
.progress-sm {
	height: 10px;
	@include border-radius(1px);
	.progress-bar {
		@include border-radius(1px);
	}
}
.progress-xs {
	height: 7px;
	@include border-radius(1px);
	.progress-bar {
		@include border-radius(1px);
	}
}
.progress-xxs {
	height: 3px;
	@include border-radius(1px);
	.progress-bar {
		@include border-radius(1px);
	}
}
.progress-group {
	.progress-text {
		font-weight: 600;
	}
	.progress-number {
		float: right;
	}
}
.progress-bar-light-blue {
	background-color: $btn_primary;
}
.progress-bar-primary {
	background-color: $btn_primary;
}
.progress-striped {
	.progress-bar-light-blue {
		background-image: linear-gradient(45deg, $white_15 25%, transparent 25%, transparent 50%, $white_15 50%, $white_15 75%, transparent 75%, transparent);
	}
	.progress-bar-primary {
		background-image: linear-gradient(45deg, $white_15 25%, transparent 25%, transparent 50%, $white_15 50%, $white_15 75%, transparent 75%, transparent);
	}
	.progress-bar-green {
		background-image: linear-gradient(45deg, $white_15 25%, transparent 25%, transparent 50%, $white_15 50%, $white_15 75%, transparent 75%, transparent);
	}
	.progress-bar-success {
		background-image: linear-gradient(45deg, $white_15 25%, transparent 25%, transparent 50%, $white_15 50%, $white_15 75%, transparent 75%, transparent);
	}
	.progress-bar-aqua {
		background-image: linear-gradient(45deg, $white_15 25%, transparent 25%, transparent 50%, $white_15 50%, $white_15 75%, transparent 75%, transparent);
	}
	.progress-bar-info {
		background-image: linear-gradient(45deg, $white_15 25%, transparent 25%, transparent 50%, $white_15 50%, $white_15 75%, transparent 75%, transparent);
	}
	.progress-bar-yellow {
		background-image: linear-gradient(45deg, $white_15 25%, transparent 25%, transparent 50%, $white_15 50%, $white_15 75%, transparent 75%, transparent);
	}
	.progress-bar-warning {
		background-image: linear-gradient(45deg, $white_15 25%, transparent 25%, transparent 50%, $white_15 50%, $white_15 75%, transparent 75%, transparent);
	}
	.progress-bar-red {
		background-image: linear-gradient(45deg, $white_15 25%, transparent 25%, transparent 50%, $white_15 50%, $white_15 75%, transparent 75%, transparent);
	}
	.progress-bar-danger {
		background-image: linear-gradient(45deg, $white_15 25%, transparent 25%, transparent 50%, $white_15 50%, $white_15 75%, transparent 75%, transparent);
	}
}
.progress-bar-green {
	background-color: $btn_success_light;
}
.progress-bar-success {
	background-color: $btn_success_light;
}
.progress-bar-aqua {
	background-color: $btn_info;
}
.progress-bar-info {
	background-color: $btn_info;
}
.progress-bar-yellow {
	background-color: $btn_warning;
}
.progress-bar-warning {
	background-color: $btn_warning;
}
.progress-bar-red {
	background-color: $btn_error;
}
.progress-bar-danger {
	background-color: $btn_error;
}
.info-box {
	.progress {
		background: $black_20;
		margin: 5px -10px;
		height: 2px;
		@include border-radius(0);
		.progress-bar {
			@include border-radius(0);
			background: $white;
		}
	}
}
.table {
	tr {
		> td .progress {
			margin: 0;
		}
		td .progress {
			margin-top: 5px;
		}
    }
}