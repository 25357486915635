.checkheck {
    position: relative;
	.checkbox {
		display: block;
		height: 20px;
		width: 20px;
		border: 1px solid $main-color;
        cursor: pointer;
        position: absolute;
        left: calc(50% - 10px);
        top: calc(50% - -15px);
		&.checked::after {
				display: block;
				border: 6px solid;
				content: " ";
				border-radius: 50%;
				width: 0;
				height: 0;
				color: $main-color;
				margin-left: 3px;
				margin-top: 3px;
		}
    }
    > label {
        padding-left: 0;
    }
}

.input-change {
    input, select {
        width: 100%;
    }
}

.dropdown-results {
    display: none;
    position: absolute;
    background-color: #fff;
    z-index: 9000;
    top: calc(100% - 20px);
    border: 1px solid #ccc;
    padding: 5px;
    max-height: 300px;
    overflow-y: scroll;
    p {
        margin: 0;
        padding: 3px 5px;
        cursor: pointer;
        &:hover {
            color: #fff;
            background-color: #007bff;
        }
    }
}