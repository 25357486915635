//colors
$color_astral_approx: #367fa9;
$color_mischka_approx: #d2d6de;
$color_boston_blue_approx: #3c8dbc;
$color_black_haze_approx: #f6f6f6;
$color_alabaster_approx: #f9fafc;
$color_tundora_approx: #444444;
$color_gunsmoke_approx: #848484;
$color_wild_sand_approx: #f4f4f5;
$color_tapa_approx: #777777;
$color_storm_dust_approx: #666;
$color_mountain_mist_approx: #999;

.skin-blue-light {
	.main-header {
		.navbar {
			background-color: $color_boston_blue_approx;
			.nav {
				> li > a {
					color: $white;
					&:hover {
						background: $black_10;
						color: $color_black_haze_approx;
					}
					&:active {
						background: $black_10;
						color: $color_black_haze_approx;
					}
					&:focus {
						background: $black_10;
						color: $color_black_haze_approx;
					}
				}
				.open > a {
					background: $black_10;
					color: $color_black_haze_approx;
					&:hover {
						background: $black_10;
						color: $color_black_haze_approx;
					}
					&:focus {
						background: $black_10;
						color: $color_black_haze_approx;
					}
				}
				> .active > a {
					background: $black_10;
					color: $color_black_haze_approx;
				}
			}
			.sidebar-toggle {
				color: $white;
				&:hover {
					color: $color_black_haze_approx;
					background: $black_10;
					background-color: $color_astral_approx;
				}
			}
		}
		.logo {
			background-color: $color_boston_blue_approx;
			color: $white;
			border-bottom: 0 solid transparent;
			&:hover {
				background-color: $color_boston_blue_approx;
			}
		}
		li.user-header {
			background-color: $color_boston_blue_approx;
		}
	}
	.content-header {
		background: transparent;
	}
	.wrapper {
		background-color: $color_alabaster_approx;
	}
	.main-sidebar {
		background-color: $color_alabaster_approx;
		border-right: 1px solid $color_mischka_approx;
	}
	.left-side {
		background-color: $color_alabaster_approx;
	}
	.sidebar-menu {
		> li {
			@include transition(border-left-color 0.3s ease);
			&.header {
				color: $color_gunsmoke_approx;
				background: $color_alabaster_approx;
			}
			> a {
				border-left: 3px solid transparent;
				font-weight: 600;
			}
			&.active {
				border-left-color: $color_boston_blue_approx;
				> a {
					color: $black;
					background: $color_wild_sand_approx;
					font-weight: 600;
				}
			}
			&:hover > a {
				color: $black;
				background: $color_wild_sand_approx;
			}
			> .treeview-menu {
				background: $color_wild_sand_approx;
			}
		}
		.treeview-menu > li {
			> a {
				color: $color_tapa_approx;
				&:hover {
					color: $black;
				}
			}
			&.active > a {
				color: $black;
				font-weight: 600;
			}
		}
	}
	.sidebar-form {
		@include border-radius(3px);
		border: 1px solid $color_mischka_approx;
		margin: 10px;
		input[type="text"] {
			@include box-shadow(none);
			background-color: $white;
			border: 1px solid transparent;
			height: 35px;
			color: $color_storm_dust_approx;
			@include border-radius(2px 0 0 2px);
			&:focus {
				background-color: $white;
				color: $color_storm_dust_approx;
				+ .input-group-btn .btn {
					background-color: $white;
					color: $color_storm_dust_approx;
					border-left-color: $white;
				}
			}
		}
		.btn {
			@include box-shadow(none);
			background-color: $white;
			border: 1px solid transparent;
			height: 35px;
			color: $color_mountain_mist_approx;
			@include border-radius(2px 0 0 2px);
		}
	}
	.main-footer {
		border-top-color: $color_mischka_approx;
	}
	.user-panel > .info {
		color: $color_tundora_approx;
		> a {
			color: $color_tundora_approx;
		}
	}
	.sidebar a {
		color: $color_tundora_approx;
		&:hover {
			text-decoration: none;
		}
	}
}
@media(max-width: 767px) {
	.skin-blue-light .main-header .navbar .dropdown-menu li {
		&.divider {
			background-color: $white_10;
		}
		a {
			color: $white;
			&:hover {
				background: $color_astral_approx;
			}
		}
	}
}
@media(min-width: 768px) {
	.skin-blue-light.sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu {
		border-left: 1px solid $color_mischka_approx;
	}
}
.skin-blue.layout-top-nav .main-header > .logo {
	background-color: $color_boston_blue_approx;
	color: $white;
	border-bottom: 0 solid transparent;
	&:hover {
		background-color: $color_boston_blue_approx;
	}
}