.browse {
    position: relative;
}
.browse-button {
    position: absolute;
    bottom: 0;
    left: 0;
}
.browse-input {
    padding-left: 90px;
}