.main-header {
	.sidebar-toggle {
		float: left;
		background-color: transparent;
		background-image: none;
		padding: 15px;
		font-family: $font_5;
		color: $sidebar-text-color-light;
		&:hover {
			color: $white;
		}
		&:focus {
			background: transparent;
		}
		&:active {
			background: transparent;
		}
		.icon-bar {
			display: none;
		}
    }
}
.main-sidebar {
	position: absolute;
	top: 0;
	left: 0;
	padding-top: 50px;
	min-height: 100%;
	width: 230px;
	z-index: 810;
	background-color: $sidebar-background-color;
	height: 100vh;
	// overflow-y: auto;
	@include transition(transform 0.3s ease-in-out, width 0.3s ease-in-out);
	.user-panel {
		white-space: nowrap;
		overflow: hidden;
	}
}
.sidebar {
	padding-bottom: 10px;
}
.sidebar-form {
	overflow: hidden;
	text-overflow: clip;
	input:focus {
		border-color: transparent;
	}
}
.sidebar-menu {
	list-style: none;
	margin: 0;
	padding: 0;
	white-space: nowrap;
	overflow: hidden;
	> li {
		position: relative;
		margin: 0;
		padding: 0;
		> a {
			color: $sidebar-text-color;
			&:hover {
				color: $sidebar-text-color-light;
			}
			padding: 12px 5px 12px 15px;
			display: block;
			> {
				.fa, .fas, .far, .fas, .fab, .ion, .glyphicon {
					width: 20px;
				}
			}
		}
		.label {
			margin-right: 5px;
		}
		.badge {
			margin-right: 5px;
			margin-top: 3px;
		}
		&.header {
			white-space: nowrap;
			overflow: hidden;
			overflow: hidden;
			text-overflow: clip;
		}
		&.treeview {
			border-left-width: 3px;
			border-left-style: solid;
			border-left-color: $main_color_light;
		}
		&.active {
			border-left-color: $border_line;
			> a {
				color: $white;
				background: $main_color_light;
			}
		}
	}
	li {
		h4 {
			color: $white;
			padding-left: 10px;
		}
		&.header {
			padding: 10px 25px 10px 15px;
			font-size: 1.35rem;
			color: $sidebar-text-color;
		}
		> a {
			position: relative;
			color: $sidebar-text-color;
			&:hover {
				color: $sidebar-text-color-light;
			}
			> {
				.fa-angle-left {
					width: auto;
					height: auto;
					padding: 0;
					margin-right: 10px;
					@include transition(transform 0.5s ease);
					position: absolute;
					top: 50%;
					right: 10px;
					margin-top: -8px;
				}
				.pull-right-container {
					position: absolute;
					right: 10px;
					top: 50%;
					margin-top: -7px;
					> .fa-angle-left {
						width: auto;
						height: auto;
						padding: 0;
						margin-right: 10px;
						@include transition(transform 0.5s ease);
					}
				}
			}
		}
	}
	&:hover {
		overflow: visible;
	}
	.menu-open > a > {
		.fa-angle-left {
			@include transform(rotate(-90deg));
		}
		.pull-right-container > .fa-angle-left {
			@include transform(rotate(-90deg));
		}
	}
	.active > .treeview-menu {
		display: block;
	}
}


.treeview-menu {
	display: none;
	list-style: none;
	padding: 0;
	margin: 0;
	padding-left: 5px;
	background-color: $sidebar-background-color-light;
	.treeview-menu {
		padding-left: 20px;
	}
	> li {
		margin: 0;
		> a {
			padding: 5px 5px 5px 15px;
			display: block;
			font-size: 14px;
			> {
				.fa, .fas, .far, .fas, .fab, .ion, .glyphicon {
					width: 20px;
				}
				.fa-angle-left {
					width: auto;
				}
				.fa-angle-down {
					width: auto;
				}
				.pull-right-container > {
					.fa-angle-left {
						width: auto;
					}
					.fa-angle-down {
						width: auto;
					}
				}
			}
		}
		&.active > a {
			color: $white;
			font-weight: 600;
		}
	}
}
.sidebar-expanded-on-hover {
	.main-footer {
		margin-left: 50px;
	}
	.content-wrapper {
		margin-left: 50px;
	}
	.main-sidebar {
		@include box-shadow(3px 0 8px $black_125);
	}
}