
// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

// Variables
$PATH: "/public" !default;
@import "partials/env";
@debug("current env path is: " + $PATH);

@import "partials/variables";

// Mixins
@import "partials/mixins";
// Fonts
@import "partials/fonts";
// Normalize styles - set detaults
@import "partials/normalize";

@debug('Partials loaded.');

// INCLUDES
@import '~bootstrap/scss/bootstrap';
$fa-font-path: $PATH+"/fonts/fontawesome";
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~jquery-ui/themes/base/all.css';
//@import '~jquery-ui-timepicker-addon/src/jquery-ui-timepicker-addon.css';
@import '~jquery-datetimepicker/build/jquery.datetimepicker.min.css';
@import '~bootstrap-daterangepicker/daterangepicker.css';
@import '~icheck/skins/all.css';

@import '~fullcalendar/main.css';
@import "includes/fullcalendar";

@import "includes/dataTables";
@import "includes/dataTablesButtons";

@import "~select2/src/scss/core";
@import "includes/select2";

@debug('Addons loaded.');

// AdminLTE Theme
@import "includes/admin-lte";
@import "includes/admin-lte-skin";
@import "includes/admin-lte-sidebar";
@import "includes/admin-lte-navigation";
@import "includes/admin-lte-modal";
@import "includes/admin-lte-responsive";
@import "includes/admin-lte-progress";
//@import "includes/admin-lte-icons";

@debug('AdminLTE loaded.');

// Scheduler files
@import "components/modal";
@import "components/general";
@import "components/bootstrap";
@import "components/header";
@import "components/footer";
@import "components/images";
@import "components/import";
@import "components/option";
@import "components/forms/inputs";
@import "components/forms/form";


.calendar-bubble-wrapper {
    background-color: $background_light;
    border-radius: 8px;
    border: 1px solid $gray_dark;
    position:absolute;
    bottom: 25px;
    left: 10px;
    z-index: 1000;
    color: $text_main;
    .calendar-bubble-inner {
        margin: 15px;
        p {
            margin-bottom: 5px;
        }
    }
}

.ui-timepicker {
    box-sizing: content-box;
    display: block;
    height: 205px;
    list-style: none outside none;
    margin: 0;
    padding: 0 1px;
    text-align: center;
    &-container {
        position: absolute;
        overflow: hidden;
        box-sizing: border-box;
    }
    &-viewport {
        box-sizing: content-box;
        display: block;
        height: 205px;
        margin: 0;
        padding: 0;
        overflow: auto;
        overflow-x: hidden; /* IE */
    }
    &-standard {
        /* overwrites .ui-widget */
        font-family: Verdana,Arial,sans-serif;
        font-size: 1.1em;
        /* overwrites .ui-widget-content */
        background-color: #FFF;
        border: 1px solid #AAA;
        color: #222;
        /* overwrites .ui-menu */
        margin: 0;
        padding: 2px;
        .ui-state-hover {
            /* overwrites .ui-state-hover */
            background-color: #DADADA;
            border: 1px solid #999;
            font-weight: normal;
            color: #212121;
        
        }
        a {
            border: 1px solid transparent;
            color: #222;
            display: block;
            padding: 0.2em 0.4em;
            text-decoration: none;
        }
        .ui-menu-item {
            /* overwrites .ui-menu and .ui-menu-item */
            /*clear: left;
            float: left;*/
            margin: 0;
            padding: 0;
        }
    }
    &-corners,
    &-corners .ui-corner-all {
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
        border-radius: 4px;
    }
    &-hidden {
        /* overwrites .ui-helper-hidden */
        display: none;
    }
    &-no-scrollbar .ui-timepicker {
        border: none;
    }
}

.ui-datepicker {
    z-index: 9999 !important;
    &-calendar {
        td > a {
            text-align: center;
        }
        .ui-state-highlight {
            background-color: $main_color_light;
            border: 1px solid $main_color;
            color: $white;
        }
        .ui-state-active {
            background-color: $main_color_2;
            border: 1px solid $main_color_2;
            color: $white;
        }
    }
    .ui-datepicker-header {
        background-color: $main_color;
        .ui-corner-all {
            border: none;
            cursor: pointer;
            &:hover {
                background-color: $main_color_light;
            }
        }
    }
}


@debug('CSS loading done.');