.main-header {
    .user-menu .nav-item > a {
        color: $main_color;
        width: 100%;
        padding: 7px;
        display: block;
        &:hover {
            color: $white;
        }
        
    }
    .menu-item {
        text-align: center;
    }
    .lang-switch{
        text-transform: uppercase;
        form {
            display: none;
        }
        .lang-select {
            display: block;
            text-align: center;
            font-size: 1.5em;
            &:hover{
                color: $white;
                background-color: $main-color;
                cursor: pointer;
            }
        }
    }
    .dropdown-item{
        padding-left: 10px;
        display: block;
        font-size: 1.2em;
        line-height: 1.5em;
        &:hover{
            color: $white;
            background-color: $main-color;
        }
    }
    .logo{
        img {
            max-width: 140px;
            margin-top: -20px;
        }
    }
    .nav-logo {
        display: none;
        img {
            max-width: 140px;
            padding-left: 15px;
        }
    }

    @include media-breakpoint-down(sm) {
        .logo {
            display: none;
        }
        .nav-logo {
            display: block;
        }
    }
}