html {
	height: 100%;
}
body {
	height: 100%;
	font-family: $font_0, $font_1, $font_2, $font_3, $font_4;
	font-weight: 400;
	overflow-x: hidden;
	overflow-y: auto;
	height: auto;
	min-height: 100%;
	.wrapper {
		height: auto;
		min-height: 100%;
	}
}
.layout-boxed {
	background-color: $background_light;
	html {
		height: 100%;
	}
	body {
		height: 100%;
	}
	.wrapper {
		max-width: 1250px;
		margin: 0 auto;
		min-height: 100%;
		@include box-shadow(0 0 8px $black_50);
		position: relative;
	}
}
.wrapper {
	height: 100%;
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}
.pull-right {
    float: right;
}
.pull-left {
    float: left;
}
.content-wrapper {
	@extend %extend_1;
	// min-height: 100%;
	background-color: $background_main;
	z-index: 800;
	position: relative;
	overflow-y: scroll;
}
.main-footer {
	@extend %extend_1;
	background: $white;
	padding: 15px;
	color: $text_main;
	border-top: 1px solid $border_line;
}
.layout-top-nav {
	.content-wrapper {
		margin-left: 0;
	}
	.main-footer {
		margin-left: 0;
	}
	.main-header .navbar {
		margin-left: 0;
	}
}
.fixed {
	.main-header {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
	}
	.main-sidebar {
		position: fixed;
	}
	.left-side {
		position: fixed;
	}
	.content-wrapper {
		padding-top: 50px;
	}
	.right-side {
		padding-top: 50px;
	}
	.wrapper {
		overflow: hidden;
	}
	.control-sidebar {
		position: fixed;
		height: 100%;
		overflow-y: auto;
		padding-bottom: 50px;
	}
	&.layout-boxed .wrapper {
		max-width: 100%;
	}
}
.hold-transition {
	.content-wrapper {
		@include transition(none);
	}
	.right-side {
		@include transition(none);
	}
	.main-footer {
		@include transition(none);
	}
	.main-sidebar {
		@include transition(none);
	}
	.left-side {
		@include transition(none);
	}
	.main-header {
		.navbar {
			@include transition(none);
		}
		.logo {
			@include transition(none);
		}
	}
	.menu-open .fa-angle-left {
		@include transition(none);
	}
}
.content {
	min-height: 250px;
	padding: 15px;
	margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
	padding-right: 15px;
}
h1 {
	font-family: $font_0, $font_4;
}
h2 {
	font-family: $font_0, $font_4;
}
h3 {
	font-family: $font_0, $font_4;
}
h4 {
	font-family: $font_0, $font_4;
}
h5 {
	font-family: $font_0, $font_4;
}
h6 {
	font-family: $font_0, $font_4;
}
.h1 {
	font-family: $font_0, $font_4;
}
.h2 {
	font-family: $font_0, $font_4;
}
.h3 {
	font-family: $font_0, $font_4;
}
.h4 {
	font-family: $font_0, $font_4;
}
.h5 {
	font-family: $font_0, $font_4;
}
.h6 {
	font-family: $font_0, $font_4;
}
a {
	color: $btn_primary;
	&:hover {
		outline: none;
		text-decoration: none;
		color: $main_color_2;
	}
	&:active {
		outline: none;
		text-decoration: none;
		color: $main_color_2;
	}
	&:focus {
		outline: none;
		text-decoration: none;
		color: $main_color_2;
	}
}
.page-header {
	margin: 10px 0 20px;
	font-size: 22px;
	> small {
		color: $gray;
		display: block;
		margin-top: 5px;
	}
}
.main-header {
	position: relative;
	max-height: 100px;
	z-index: 1030;
	.logo {
		@include transition(width 0.3s ease-in-out);
		display: block;
		float: left;
		height: 50px;
		font-size: 20px;
		line-height: 50px;
		text-align: center;
		width: 230px;
		font-family: $font_1, $font_2, $font_3, $font_4;
		padding: 6px 15px;
		font-weight: 300;
		color: $sidebar-text-color;
		overflow: hidden;
		background-color: $sidebar-background-color;
		.logo-lg {
			display: block;
		}
		.logo-mini {
			display: none;
		}
	}
	.navbar-brand {
		color: $white;
	}
}
.content-header {
	position: relative;
	padding: 15px 15px 0 15px;
	> h1 {
		margin: 0;
		font-size: 24px;
		> small {
			font-size: 15px;
			display: inline-block;
			padding-left: 4px;
			font-weight: 300;
		}
	}
	> .breadcrumb {
		float: right;
		background: transparent;
		margin-top: 0;
		margin-bottom: 0;
		font-size: 12px;
		padding: 7px 5px;
		position: absolute;
		top: 15px;
		right: 10px;
		@include border-radius(2px);
		> li {
			> a {
				color: $text_main;
				text-decoration: none;
				display: inline-block;
				> {
					.fa {
						margin-right: 5px;
					}
					.glyphicon {
						margin-right: 5px;
					}
					.ion {
						margin-right: 5px;
					}
				}
			}
			+ li:before {
				content: ' >\00a0';
			}
		}
	}
}
.user-panel {
	position: relative;
	width: 100%;
	padding: 10px;
	overflow: hidden;
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
	> {
		.info {
			padding: 5px 5px 5px 15px;
			line-height: 1;
			position: absolute;
			left: 55px;
			> p {
				font-weight: 600;
				margin-bottom: 9px;
			}
			> a {
				text-decoration: none;
				padding-right: 5px;
				margin-top: 3px;
				font-size: 11px;
				> {
					.fa {
						margin-right: 3px;
					}
					.ion {
						margin-right: 3px;
					}
					.glyphicon {
						margin-right: 3px;
					}
				}
			}
		}
		.image > img {
			width: 100%;
			max-width: 45px;
			height: auto;
		}
	}
}

.control-sidebar-bg {
	position: fixed;
	z-index: 1000;
	bottom: 0;
	top: 0;
	right: -230px;
	width: 230px;
	@include transition(right 0.3s ease-in-out);
}
.control-sidebar {
	top: 0;
	right: -230px;
	width: 230px;
	@include transition(right 0.3s ease-in-out);
	position: absolute;
	padding-top: 50px;
	z-index: 1010;
	&.control-sidebar-open {
		right: 0;
		+ .control-sidebar-bg {
			right: 0;
		}
	}
	> .tab-content {
		padding: 10px 15px;
	}
}
.control-sidebar-open {
	.control-sidebar-bg {
		right: 0;
	}
	.control-sidebar {
		right: 0;
	}
}
@media(max-width: 768px) {
	.login-box {
		width: 90%;
		margin-top: 20px;
	}
	.register-box {
		width: 90%;
		margin-top: 20px;
	}
	.nav-tabs.control-sidebar-tabs {
		display: table;
		> li {
			display: table-cell;
		}
	}
}
.control-sidebar-heading {
	font-weight: 400;
	font-size: 16px;
	padding: 10px 0;
	margin-bottom: 10px;
}
.control-sidebar-subheading {
	display: block;
	font-weight: 400;
	font-size: 14px;
}
.control-sidebar-menu {
	list-style: none;
	padding: 0;
	margin: 0 -15px;
	.menu-icon {
		float: left;
		width: 35px;
		height: 35px;
		@include border-radius(50%);
		text-align: center;
		line-height: 35px;
	}
	.menu-info {
		margin-left: 45px;
		margin-top: 3px;
		> p {
			margin: 0;
			font-size: 11px;
		}
		> .control-sidebar-subheading {
			margin: 0;
		}
	}
	.progress {
		margin: 0;
	}
	> li > a {
		display: block;
		padding: 10px 15px;
		&:before {
			content: " ";
			display: table;
		}
		&:after {
			content: " ";
			display: table;
			clear: both;
		}
		> .control-sidebar-subheading {
			margin-top: 0;
		}
	}
}
.control-sidebar-dark {
	color: $text_gray;
	background: $background_dark;
	.control-sidebar-heading {
		color: $white;
	}
	.control-sidebar-subheading {
		color: $white;
	}
	+ .control-sidebar-bg {
		background: $background_dark;
	}
	.nav-tabs.control-sidebar-tabs {
		border-bottom: $background_dark;
		> li {
			> a {
				background: $background_darker;
				color: $text_gray;
				border-left-color: $border_line_dark;
				border-bottom-color: $border_line_dark;
				&:hover {
					border-left-color: $border_line_dark;
					border-bottom-color: $border_line_dark;
					background: $background_dark;
					color: $white;
				}
				&:active {
					background: $background_dark;
				}
			}
			&.active > a {
				background: $background_dark;
				color: $white;
				&:hover {
					background: $background_dark;
					color: $white;
				}
				&:active {
					background: $background_dark;
					color: $white;
				}
			}
		}
	}
	.control-sidebar-menu > li > a {
		&:hover {
			background: $background_dark;
		}
		.menu-info > p {
			color: $text_gray;
		}
	}
}
.control-sidebar-light {
	color: $text_gray;
	background: $background_light;
	border-left: 1px solid $border_line;
	.control-sidebar-heading {
		color: $text_black;
	}
	.control-sidebar-subheading {
		color: $text_black;
	}
	.control-sidebar-menu {
		margin-left: -14px;
		> li > a {
			&:hover {
				background: $background_light;
			}
			.menu-info > p {
				color: $text_gray;
			}
		}
	}
	+ .control-sidebar-bg {
		background: $background_light;
		border-left: 1px solid $border_line;
	}
	.nav-tabs.control-sidebar-tabs {
		border-bottom: $border_line;
		> li {
			> a {
				background: $background_main;
				color: $text_main;
				border-left-color: $border_line;
				border-bottom-color: $border_line;
				&:hover {
					border-left-color: $border_line;
					border-bottom-color: $border_line;
					background: $background_light;
				}
				&:active {
					background: $background_light;
				}
			}
			&.active > a {
				background: $background_light;
				color: $text_black;
				&:hover {
					background: $background_light;
					color: $text_black;
				}
				&:active {
					background: $background_light;
					color: $text_black;
				}
			}
		}
	}
}
.dropdown-menu {
	@include box-shadow(none);
	border-color: $border_line;
	> li > a {
		color: $gray;
		> {
			.glyphicon {
				margin-right: 10px;
			}
			.fa {
				margin-right: 10px;
			}
			.ion {
				margin-right: 10px;
			}
		}
		&:hover {
			background-color: $btn_default;
			color: $text_main;
		}
	}
	> .divider {
		background-color: $border_line;
	}
}
.open {
	.btn-box-tool {
		color: $btn_primary_muted;
	}
	&:not(.dropup) > .animated-dropdown-menu {
		backface-visibility: visible !important;
		animation: flipInX 0.7s both;
	}
}
@keyframes flipInX {
	0% {
		@include transform(perspective(400px) rotate3d(1, 0, 0, 90deg));
		transition-timing-function: ease-in;
		opacity: 0;
	}
	40% {
		@include transform(perspective(400px) rotate3d(1, 0, 0, -20deg));
		transition-timing-function: ease-in;
	}
	60% {
		@include transform(perspective(400px) rotate3d(1, 0, 0, 10deg));
		opacity: 1;
	}
	80% {
		@include transform(perspective(400px) rotate3d(1, 0, 0, -5deg));
	}
	100% {
		@include transform(perspective(400px));
	}
}
@-webkit-keyframes flipInX {
	0% {
		opacity: 0;
	}
	40% {
	}
	60% {
		opacity: 1;
	}
	80% {
	}
	100% {
	}
}
.form-control {
	@include border-radius(0);
	@include box-shadow(none);
	border-color: $border_line;
	&:-ms-input-placeholder {
		color: $gray;
		opacity: 1;
	}
	&:not(select) {
		appearance: none;
	}
}
.form-control::-moz-placeholder {
	color: $gray;
	opacity: 1;
}
.form-control::-webkit-input-placeholder {
	color: $gray;
	opacity: 1;
}
.form-group {
	&.has-success {
		label {
			color: $btn_success_light;
		}
		.form-control {
			border-color: $btn_success_light;
			@include box-shadow(none);
		}
		.input-group-addon {
			border-color: $btn_success_light;
			@include box-shadow(none);
		}
		.help-block {
			color: $btn_success_light;
		}
	}
	&.has-warning {
		label {
			color: $btn_warning;
		}
		.form-control {
			border-color: $btn_warning;
			@include box-shadow(none);
		}
		.input-group-addon {
			border-color: $btn_warning;
			@include box-shadow(none);
		}
		.help-block {
			color: $btn_warning;
		}
	}
	&.has-error {
		label {
			color: $btn_error;
		}
		.form-control {
			border-color: $btn_error;
			@include box-shadow(none);
		}
		.input-group-addon {
			border-color: $btn_error;
			@include box-shadow(none);
		}
		.help-block {
			color: $btn_error;
		}
	}
}

.table {
	&.no-border {
		border: 0;
		td {
			border: 0;
		}
		th {
			border: 0;
		}
	}
	&.align {
		th {
			text-align: left;
		}
		td {
			text-align: right;
		}
	}
	> thead > tr {
		> th {
			border-top: 1px solid $border_line;
			border-bottom: 2px solid $border_line;
		}
		> td {
			border-top: 1px solid $border_line;
		}
	}
	> tbody > tr {
		> th {
			border-top: 1px solid $border_line;
		}
		> td {
			border-top: 1px solid $border_line;
		}
	}
	> tfoot > tr {
		> th {
			border-top: 1px solid $border_line;
		}
		> td {
			border-top: 1px solid $border_line;
		}
	}
}

.small-box {
	@include border-radius(2px);
	position: relative;
	display: block;
	margin-bottom: 20px;
	@include box-shadow(0 1px 1px $black_10);
	> {
		.inner {
			padding: 10px;
		}
		.small-box-footer {
			position: relative;
			text-align: center;
			padding: 3px 0;
			color: $white;
			color: $white_80;
			display: block;
			z-index: 10;
			background: $black_10;
			text-decoration: none;
			&:hover {
				color: $white;
				background: $black_15;
			}
		}
	}
	h3 {
		font-size: 38px;
		font-weight: bold;
		margin: 0 0 10px 0;
		white-space: nowrap;
		padding: 0;
		z-index: 5;
	}
	p {
		font-size: 15px;
		z-index: 5;
		> small {
			display: block;
			color: $background_light;
			font-size: 13px;
			margin-top: 5px;
		}
	}
	.icon {
		@include transition(all 0.3s linear);
		position: absolute;
		top: -10px;
		right: 10px;
		z-index: 0;
		font-size: 90px;
		color: $black_15;
	}
	&:hover {
		text-decoration: none;
		color: $background_light;
		.icon {
			font-size: 95px;
		}
	}
}
.box {
	position: relative;
	@include border-radius(3px);
	background: $white;
	border-top: 3px solid $border_line;
	margin-bottom: 20px;
	width: 100%;
	@include box-shadow(0 1px 1px $black_10);
	&.box-primary {
		border-top-color: $btn_primary;
	}
	&.box-info {
		border-top-color: $btn_info;
	}
	&.box-danger {
		border-top-color: $btn_error;
	}
	&.box-warning {
		border-top-color: $btn_warning;
	}
	&.box-success {
		border-top-color: $btn_success_light;
	}
	&.box-default {
		border-top-color: $border_line;
	}
	&.collapsed-box {
		.box-body {
			display: none;
		}
		.box-footer {
			display: none;
		}
	}
	.border-right {
		border-right: 1px solid $border_line;
	}
	.border-left {
		border-left: 1px solid $border_line;
	}
	&.box-solid {
		border-top: 0;
		&.box-default {
			border: 1px solid $border_line;
			> .box-header {
				color: $text_main;
				background: $border_line;
				background-color: $border_line;
				a {
					color: $text_main;
				}
				.btn {
					color: $text_main;
				}
			}
		}
		&.box-primary {
			border: 1px solid $btn_primary;
			> .box-header {
				color: $white;
				background: $btn_primary;
				background-color: $btn_primary;
				a {
					color: $white;
				}
				.btn {
					color: $white;
				}
			}
		}
		&.box-info {
			border: 1px solid $btn_info;
			> .box-header {
				color: $white;
				background: $btn_info;
				background-color: $btn_info;
				a {
					color: $white;
				}
				.btn {
					color: $white;
				}
			}
		}
		&.box-danger {
			border: 1px solid $btn_error;
			> .box-header {
				color: $white;
				background: $btn_error;
				background-color: $btn_error;
				a {
					color: $white;
				}
				.btn {
					color: $white;
				}
			}
		}
		&.box-warning {
			border: 1px solid $btn_warning;
			> .box-header {
				color: $white;
				background: $btn_warning;
				background-color: $btn_warning;
				a {
					color: $white;
				}
				.btn {
					color: $white;
				}
			}
		}
		&.box-success {
			border: 1px solid $btn_success_light;
			> .box-header {
				color: $white;
				background: $btn_success_light;
				background-color: $btn_success_light;
				a {
					color: $white;
				}
				.btn {
					color: $white;
				}
			}
		}
		> .box-header {
			.btn {
				&.btn-default {
					background: transparent;
				}
				&:hover {
					background: $black_10;
				}
			}
			a:hover {
				background: $black_10;
			}
			> .box-tools .btn {
				border: 0;
				@include box-shadow(
none);
			}
		}
	}
	.knob-label {
		text-align: center;
		color: $text_main;
		font-weight: 100;
		font-size: 12px;
		margin-bottom: 0.3em;
	}
	> {
		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		.loading-img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	.overlay {
		z-index: 50;
		background: $white_70;
		@include border-radius(3px);
		&.dark {
			background: $black_50;
		}
		> .fa {
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -15px;
			margin-top: -15px;
			color: $black;
			font-size: 30px;
		}
	}
	.datepicker-inline {
		width: 100%;
		.datepicker-days {
			width: 100%;
			> table {
				width: 100%;
				td {
					&:hover {
						background-color: $white_30;
					}
					&.day {
						&.old {
							color: $gray;
						}
						&.new {
							color: $gray;
						}
					}
				}
			}
			td {
				&:hover {
					background-color: $white_30;
				}
				&.day {
					&.old {
						color: $gray;
					}
					&.new {
						color: $gray;
					}
				}
			}
		}
		> table {
			width: 100%;
			td {
				&:hover {
					background-color: $white_30;
				}
				&.day {
					&.old {
						color: $gray;
					}
					&.new {
						color: $gray;
					}
				}
			}
		}
		td {
			&:hover {
				background-color: $white_30;
			}
			&.day {
				&.old {
					color: $gray;
				}
				&.new {
					color: $gray;
				}
			}
		}
	}
	.nav-stacked > li {
		border-bottom: 1px solid $border_line;
		margin: 0;
		&:last-of-type {
			border-bottom: none;
		}
	}
	&.height-control .box-body {
		max-height: 300px;
		overflow: auto;
	}
	&.box-solid[class*='bg'] > .box-header {
		color: $white;
	}
	.box-group > .box {
		margin-bottom: 5px;
	}
}
.overlay-wrapper {
	> {
		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		.loading-img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	.overlay {
		z-index: 50;
		background: $white_70;
		@include border-radius(3px);
		&.dark {
			background: $black_50;
		}
		> .fa {
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -15px;
			margin-top: -15px;
			color: $black;
			font-size: 30px;
		}
	}
}
.box-header {
	color: $text_main;
	display: block;
	padding: 10px;
	position: relative;
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
	&.with-border {
		border-bottom: 1px solid $border_line;
	}
	> {
		.fa {
			display: inline-block;
			font-size: 18px;
			margin: 0;
			line-height: 1;
			margin-right: 5px;
		}
		.glyphicon {
			display: inline-block;
			font-size: 18px;
			margin: 0;
			line-height: 1;
			margin-right: 5px;
		}
		.ion {
			display: inline-block;
			font-size: 18px;
			margin: 0;
			line-height: 1;
			margin-right: 5px;
		}
		.box-tools {
			position: absolute;
			right: 10px;
			top: 5px;
			[data-toggle="tooltip"] {
				position: relative;
			}
			&.pull-right .dropdown-menu {
				right: 0;
				left: auto;
			}
			.dropdown-menu > li > a {
				color: $text_main!important;
			}
		}
	}
	.box-title {
		display: inline-block;
		font-size: 18px;
		margin: 0;
		line-height: 1;
	}
}
.box-body {
	@include border-radius(0 0 3px 3px);
	padding: 10px;
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
	.fc {
		margin-top: 5px;
	}
	.full-width-chart {
		margin: -19px;
	}
	.box-pane {
		@include border-radius(0 0 0 3px);
	}
	.box-pane-right {
		@include border-radius(0 0 3px 0);
	}
	> .table {
		margin-bottom: 0;
	}
	&.no-padding .full-width-chart {
		margin: -9px;
	}
}
.box-footer {
	@include border-radius(0 0 3px 3px);
	border-top: 1px solid $border_line;
	padding: 10px;
	background-color: $white;
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}
.btn-box-tool {
	padding: 5px;
	font-size: 12px;
	background: transparent;
	color: $text_gray;
	&:hover {
		color: $btn_primary_muted;
	}
	&.btn:active {
		@include box-shadow(none);
	}
}
.chart-legend {
	margin: 10px 0;
	list-style: none;
	margin: 0;
	padding: 0;
}
.box-comments {
	background: $background_light;
	.box-comment {
		padding: 8px 0;
		border-bottom: 1px solid $border_line;
		&:before {
			content: " ";
			display: table;
		}
		&:after {
			content: " ";
			display: table;
			clear: both;
		}
		&:last-of-type {
			border-bottom: 0;
		}
		&:first-of-type {
			padding-top: 0;
		}
		img {
			float: left;
			float: left;
			width: 30px !important;
			height: 30px !important;
		}
	}
	.comment-text {
		margin-left: 40px;
		color: $gray;
	}
	.username {
		color: $text_main;
		display: block;
		font-weight: 600;
	}
	.text-muted {
		font-weight: 400;
		font-size: 12px;
	}
}
.todo-list {
	margin: 0;
	padding: 0;
	list-style: none;
	overflow: auto;
	> li {
		@include border-radius(2px);
		padding: 10px;
		background: $border_line;
		margin-bottom: 2px;
		border-left: 2px solid $btn_default;
		color: $text_main;
		&:last-of-type {
			margin-bottom: 0;
		}
		> input[type='checkbox'] {
			margin: 0 10px 0 5px;
		}
		.text {
			display: inline-block;
			margin-left: 5px;
			font-weight: 600;
		}
		.label {
			margin-left: 10px;
			font-size: 9px;
		}
		.tools {
			display: none;
			float: right;
			color: $btn_error;
			> {
				.fa {
					margin-right: 5px;
					cursor: pointer;
				}
				.glyphicon {
					margin-right: 5px;
					cursor: pointer;
				}
				.ion {
					margin-right: 5px;
					cursor: pointer;
				}
			}
		}
		&.done {
			color: $gray;
			.text {
				text-decoration: line-through;
				font-weight: 500;
			}
			.label {
				background: $border_line !important;
			}
		}
		&:hover .tools {
			display: inline-block;
		}
	}
	.danger {
		border-left-color: $btn_error;
	}
	.warning {
		border-left-color: $btn_warning;
	}
	.info {
		border-left-color: $btn_info;
	}
	.success {
		border-left-color: $btn_success_light;
	}
	.primary {
		border-left-color: $btn_primary;
	}
	.handle {
		display: inline-block;
		cursor: move;
		margin: 0 5px;
	}
}
.chat {
	padding: 5px 20px 5px 10px;
	.item {
		margin-bottom: 10px;
		&:before {
			content: " ";
			display: table;
		}
		&:after {
			content: " ";
			display: table;
			clear: both;
		}
		> img {
			width: 40px;
			height: 40px;
			border: 2px solid transparent;
			@include border-radius(50%);
		}
		> {
			.online {
				border: 2px solid $btn_success_light;
			}
			.offline {
				border: 2px solid $btn_error;
			}
			.message {
				margin-left: 55px;
				margin-top: -40px;
				> .name {
					display: block;
					font-weight: 600;
				}
			}
			.attachment {
				@include border-radius(3px);
				background: $border_line;
				margin-left: 65px;
				margin-right: 15px;
				padding: 10px;
				> h4 {
					margin: 0 0 5px 0;
					font-weight: 600;
					font-size: 14px;
				}
				> p {
					font-weight: 600;
					font-size: 13px;
					font-style: italic;
					margin: 0;
				}
				&:before {
					content: " ";
					display: table;
				}
				&:after {
					content: " ";
					display: table;
					clear: both;
				}
				> .filename {
					font-weight: 600;
					font-size: 13px;
					font-style: italic;
					margin: 0;
				}
			}
		}
	}
}
.box-input {
	max-width: 200px;
}

.info-box {
	display: block;
	min-height: 90px;
	background: $white;
	width: 100%;
	@include box-shadow(0 1px 1px $black_10);
	@include border-radius(2px);
	margin-bottom: 15px;
	small {
		font-size: 14px;
	}
}
.info-box-icon {
	@include border-radius(2px 0 0 2px);
	display: block;
	float: left;
	height: 90px;
	width: 90px;
	text-align: center;
	font-size: 45px;
	line-height: 90px;
	background: $black_20;
	> img {
		max-width: 100%;
	}
}
.info-box-content {
	padding: 5px 10px;
	margin-left: 90px;
}
.info-box-number {
	display: block;
	font-weight: bold;
	font-size: 18px;
}

.info-box-text {
	display: block;
	font-size: 14px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: uppercase;
}
.info-box-more {
	display: block;
}
.timeline {
	position: relative;
	margin: 0 0 30px 0;
	padding: 0;
	list-style: none;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		width: 4px;
		background: $border_line;
		left: 31px;
		margin: 0;
		@include border-radius(2px);
	}
	> li {
		position: relative;
		margin-right: 10px;
		margin-bottom: 15px;
		&:before {
			content: " ";
			display: table;
		}
		&:after {
			content: " ";
			display: table;
			clear: both;
		}
		> {
			.timeline-item {
				@include box-shadow(0 1px 1px $black_10);
				@include border-radius(3px);
				margin-top: 0;
				background: $white;
				color: $text_main;
				margin-left: 60px;
				margin-right: 15px;
				padding: 0;
				position: relative;
				> {
					.time {
						color: $gray;
						float: right;
						padding: 10px;
						font-size: 12px;
					}
					.timeline-header {
						margin: 0;
						color: $gray;
						border-bottom: 1px solid $border_line;
						padding: 10px;
						font-size: 16px;
						line-height: 1.1;
						> a {
							font-weight: 600;
						}
					}
					.timeline-body {
						padding: 10px;
					}
					.timeline-footer {
						padding: 10px;
					}
				}
			}
			.fa {
				width: 30px;
				height: 30px;
				font-size: 15px;
				line-height: 30px;
				position: absolute;
				color: $gray;
				background: $border_line;
				@include border-radius(50%);
				text-align: center;
				left: 18px;
				top: 0;
			}
			.glyphicon {
				width: 30px;
				height: 30px;
				font-size: 15px;
				line-height: 30px;
				position: absolute;
				color: $gray;
				background: $border_line;
				@include border-radius(50%);
				text-align: center;
				left: 18px;
				top: 0;
			}
			.ion {
				width: 30px;
				height: 30px;
				font-size: 15px;
				line-height: 30px;
				position: absolute;
				color: $gray;
				background: $border_line;
				@include border-radius(50%);
				text-align: center;
				left: 18px;
				top: 0;
			}
		}
	}
	> .time-label > span {
		font-weight: 600;
		padding: 5px;
		display: inline-block;
		background-color: $white;
		@include border-radius(4px);
	}
}
.btn {
	@include border-radius(3px);
	@include box-shadow(none);
	border: 1px solid transparent;
	&.uppercase {
		text-transform: uppercase;
	}
	&.btn-flat {
		@include border-radius(0);
		@include box-shadow(none);
		border-width: 1px;
	}
	&:active {
		@include box-shadow(inset 0 3px 5px $black_125);
	}
	&.btn-file {
		position: relative;
		overflow: hidden;
		> input[type='file'] {
			position: absolute;
			top: 0;
			right: 0;
			min-width: 100%;
			min-height: 100%;
			font-size: 100px;
			text-align: right;
			opacity: 0;
			filter: alpha(opacity=0);
			outline: none;
			background: $white;
			cursor: inherit;
			display: block;
		}
	}
}
.btn-default {
	background-color: $border_line !important;
	color: $white;
	border-color: $border_line !important;
	&:hover {
		background-color: $btn_default;
	}
	&:active {
		background-color: $btn_default;
	}
	&.hover {
		background-color: $btn_default;
	}
}
.btn-primary {
	background-color: $btn_primary !important;
	border-color: $btn_primary !important;
	color: $white;
	&:hover {
		background-color: $btn_primary !important;
	}
	&:active {
		background-color: $btn_primary !important;
	}
	&.hover {
		background-color: $btn_primary !important;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem $main_color_light !important;
	}
}
.btn-success {
	background-color: $btn_success_light;
	border-color: $btn_success;
	&:hover {
		background-color: $btn_success;
	}
	&:active {
		background-color: $btn_success;
	}
	&.hover {
		background-color: $btn_success;
	}
}
.btn-info {
	background-color: $btn_info;
	border-color: $btn_info_light;
	&:hover {
		background-color: $btn_info_light;
	}
	&:active {
		background-color: $btn_info_light;
	}
	&.hover {
		background-color: $btn_info_light;
	}
}
.btn-danger {
	background-color: $btn_error;
	border-color: $btn_error_light;
	&:hover {
		background-color: $btn_error_light;
	}
	&:active {
		background-color: $btn_error_light;
	}
	&.hover {
		background-color: $btn_error_light;
	}
}
.btn-warning {
	background-color: $btn_warning;
	border-color: $btn_warning_light;
	&:hover {
		background-color: $btn_warning_light;
	}
	&:active {
		background-color: $btn_warning_light;
	}
	&.hover {
		background-color: $btn_warning_light;
	}
}
.btn-outline {
	border: 1px solid $white;
	background: transparent;
	color: $white;
	&:hover {
		color: $white_70;
		border-color: $white_70;
	}
	&:active {
		color: $white_70;
		border-color: $white_70;
	}
}
.btn-link {
	color: $main-link-color;
	@include box-shadow(none);
	&:hover {
		color: $main-link-hover-color;
	}
}
.btn-app {
	@include border-radius(3px);
	position: relative;
	padding: 15px 5px;
	margin: 0 0 10px 10px;
	min-width: 80px;
	height: 60px;
	text-align: center;
	color: $gray;
	border: 1px solid $border_line;
	background-color: $border_line;
	font-size: 12px;
	> {
		.fa {
			font-size: 20px;
			display: block;
		}
		.glyphicon {
			font-size: 20px;
			display: block;
		}
		.ion {
			font-size: 20px;
			display: block;
		}
		.badge {
			position: absolute;
			top: -3px;
			right: -10px;
			font-size: 10px;
			font-weight: 400;
		}
	}
	&:hover {
		background: $border_line;
		color: $text_main;
		border-color: $gray;
	}
	&:active {
		@include box-shadow(inset 0 3px 5px $black_125);
	}
}
.callout {
	@include border-radius(3px);
	margin: 0 0 20px 0;
	padding: 15px 30px 15px 15px;
	border-left: 5px solid $border_line;
	a {
		color: $white;
		text-decoration: underline;
		&:hover {
			color: $border_line;
		}
	}
	h4 {
		margin-top: 0;
		font-weight: 600;
	}
	code {
		background-color: $white;
	}
	.highlight {
		background-color: $white;
	}
	&.callout-danger {
		border-color: $btn_error_modal;
		color: $white !important;
		background-color: $btn_error !important;
	}
	&.callout-warning {
		border-color: $btn_warning_modal;
		color: $white !important;
		background-color: $btn_warning !important;
	}
	&.callout-info {
		border-color: $btn_info_modal;
		color: $white !important;
		background-color: $btn_info !important;
	}
	&.callout-success {
		border-color: $btn_success_modal;
		color: $white !important;
		background-color: $btn_success_light !important;
	}
	p:last-child {
		margin-bottom: 0;
	}
}
.alert {
	@include border-radius(3px);
	h4 {
		font-weight: 600;
	}
	.icon {
		margin-right: 10px;
	}
	.close {
		color: $black;
		opacity: 0.2;
		filter: alpha(opacity=20);
		&:hover {
			opacity: 0.5;
			filter: alpha(opacity=50);
		}
	}
	a {
		color: $white;
		text-decoration: underline;
	}
}
.alert-success {
	border-color: $btn_success;
	color: $white !important;
	background-color: $btn_success_light !important;
}
.alert-danger {
	border-color: $btn_error_light;
	color: $white !important;
	background-color: $btn_error !important;
}
.alert-error {
	border-color: $btn_error_light;
	color: $white !important;
	background-color: $btn_error !important;
}
.alert-warning {
	border-color: $btn_warning_light;
	color: $white !important;
	background-color: $btn_warning !important;
}
.alert-info {
	border-color: $btn_info_light;
	color: $white !important;
	background-color: $btn_info !important;
}
.nav-tabs-custom {
	margin-bottom: 20px;
	background: $white;
	@include box-shadow(0 1px 1px $black_10);
	@include border-radius(3px);
	> {
		.nav-tabs {
			margin: 0;
			border-bottom-color: $border_line;
			@include border-radius(3px 3px inherit inherit);
			> li {
				border-top: 3px solid transparent;
				margin-bottom: -2px;
				margin-right: 5px;
				> a {
					color: $text_main;
					@include border-radius(0);
					background: transparent;
					margin: 0;
					&.text-muted {
						color: $gray;
					}
					&:hover {
						background: transparent;
						margin: 0;
						color: $gray;
					}
				}
				&.active {
					border-top-color: $btn_primary;
					> a {
						background-color: $white;
						color: $text_main;
						border-top-color: transparent;
						border-left-color: $border_line;
						border-right-color: $border_line;
					}
					&:hover > a {
						background-color: $white;
						color: $text_main;
					}
				}
				&:first-of-type {
					margin-left: 0;
					&.active > a {
						border-left-color: transparent;
					}
				}
				&.header {
					line-height: 35px;
					padding: 0 10px;
					font-size: 20px;
					color: $text_main;
					> {
						.fa {
							margin-right: 5px;
						}
						.glyphicon {
							margin-right: 5px;
						}
						.ion {
							margin-right: 5px;
						}
					}
				}
				&.disabled > a {
					color: $gray;
				}
				&:not(.active) > a {
					&:hover {
						border-color: transparent;
					}
					&:active {
						border-color: transparent;
					}
				}
			}
			&.pull-right {
				float: none !important;
				> li {
					float: right;
					&:first-of-type {
						margin-right: 0;
						> a {
							border-left-width: 1px;
						}
						&.active > a {
							border-left-color: $border_line;
							border-right-color: transparent;
						}
					}
				}
			}
		}
		.tab-content {
			background: $white;
			padding: 10px;
			@include border-radius(inherit inherit  3px 3px);
		}
	}
	.dropdown.open > a {
		&:active {
			background: transparent;
			color: $gray;
		}
	}
	&.tab-primary > .nav-tabs > li.active {
		border-top-color: $btn_primary;
	}
	&.tab-info > .nav-tabs > li.active {
		border-top-color: $btn_info;
	}
	&.tab-danger > .nav-tabs > li.active {
		border-top-color: $btn_error;
	}
	&.tab-warning > .nav-tabs > li.active {
		border-top-color: $btn_warning;
	}
	&.tab-success > .nav-tabs > li.active {
		border-top-color: $btn_success_light;
	}
	&.tab-default > .nav-tabs > li.active {
		border-top-color: $border_line;
	}
}
.pagination {
	> li > a {
		background: $background_light;
		color: $gray;
	}
	&.pagination-flat > li > a {
		@include border-radius(0 !important);
	}
}
.products-list {
	list-style: none;
	margin: 0;
	padding: 0;
	.product-img {
		float: left;
		img {
			width: 50px;
			height: 50px;
		}
	}
	.product-info {
		margin-left: 60px;
	}
	.product-title {
		font-weight: 600;
	}
	.product-description {
		display: block;
		color: $gray;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	> .item {
		@include border-radius(3px);
		@include box-shadow(0 1px 1px $black_10);
		padding: 10px 0;
		background: $white;
		&:before {
			content: " ";
			display: table;
		}
		&:after {
			content: " ";
			display: table;
			clear: both;
		}
	}
}
.table-bordered {
	border: 1px solid $border_line;
	> thead > tr {
		> th {
			border: 1px solid $border_line;
			border-bottom-width: 2px;
		}
		> td {
			border: 1px solid $border_line;
			border-bottom-width: 2px;
		}
	}
	> tbody > tr {
		> th {
			border: 1px solid $border_line;
		}
		> td {
			border: 1px solid $border_line;
		}
	}
	> tfoot > tr {
		> th {
			border: 1px solid $border_line;
		}
		> td {
			border: 1px solid $border_line;
		}
	}
}
.label-default {
	background-color: $border_line;
	color: $text_main;
}
.direct-chat {
	.box-body {
		@include border-radius(inherit inherit 0 0);
		position: relative;
		overflow-x: hidden;
		padding: 0;
	}
	&.chat-pane-open .direct-chat-contacts {
		@include transform(translate(0, 0));
	}
}
.direct-chat-messages {
	@include transform(translate(0, 0));
	padding: 10px;
	height: 250px;
	overflow: auto;
	@include transition(transform 0.5s ease-in-out);
}
.direct-chat-msg {
	display: block;
	margin-bottom: 10px;
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}
.direct-chat-text {
	display: block;
	@include border-radius(5px);
	position: relative;
	padding: 5px 10px;
	background: $border_line;
	border: 1px solid $border_line;
	margin: 5px 0 0 50px;
	color: $text_main;
	&:after {
		position: absolute;
		right: 100%;
		top: 15px;
		border: solid transparent;
		border-right-color: $border_line;
		content: ' ';
		height: 0;
		width: 0;
		pointer-events: none;
		border-width: 5px;
		margin-top: -5px;
	}
	&:before {
		position: absolute;
		right: 100%;
		top: 15px;
		border: solid transparent;
		border-right-color: $border_line;
		content: ' ';
		height: 0;
		width: 0;
		pointer-events: none;
		border-width: 6px;
		margin-top: -6px;
	}
}
.direct-chat-contacts {
	@include transition(transform 0.5s ease-in-out);
	@include transform(translate(101%, 0));
	position: absolute;
	top: 0;
	bottom: 0;
	height: 250px;
	width: 100%;
	background: $background_dark;
	color: $white;
	overflow: auto;
}
.right {
	.direct-chat-text {
		margin-right: 50px;
		margin-left: 0;
		&:after {
			right: auto;
			left: 100%;
			border-right-color: transparent;
			border-left-color: $border_line;
		}
		&:before {
			right: auto;
			left: 100%;
			border-right-color: transparent;
			border-left-color: $border_line;
		}
	}
	.direct-chat-img {
		float: right;
	}
}
.direct-chat-img {
	@include border-radius(50%);
	float: left;
	width: 40px;
	height: 40px;
}
.direct-chat-info {
	display: block;
	margin-bottom: 2px;
	font-size: 12px;
	.right > .direct-chat-text {
		background: $btn_info;
		border-color: $btn_info;
		color: $white;
		&:after {
			border-left-color: $btn_info;
		}
		&:before {
			border-left-color: $btn_info;
		}
	}
}
.direct-chat-name {
	font-weight: 600;
}
.direct-chat-timestamp {
	color: $gray;
}
.contacts-list {
	list-style: none;
	margin: 0;
	padding: 0;
	> li {
		border-bottom: 1px solid $black_20;
		padding: 10px;
		margin: 0;
		&:before {
			content: " ";
			display: table;
		}
		&:after {
			content: " ";
			display: table;
			clear: both;
		}
		&:last-of-type {
			border-bottom: none;
		}
	}
}
.contacts-list-img {
	@include border-radius(50%);
	width: 40px;
	float: left;
}
.contacts-list-info {
	margin-left: 45px;
	color: $white;
}
.contacts-list-name {
	display: block;
	font-weight: 600;
}
.contacts-list-status {
	display: block;
	font-size: 12px;
}
.contacts-list-date {
	color: $gray;
	font-weight: normal;
}
.contacts-list-msg {
	color: $gray;
}
.users-list {
	list-style: none;
	margin: 0;
	padding: 0;
	> li {
		width: 25%;
		float: left;
		padding: 10px;
		text-align: center;
		img {
			@include border-radius(50%);
			max-width: 100%;
			height: auto;
		}
		> a:hover {
			color: $gray;
			.users-list-name {
				color: $gray;
			}
		}
	}
}
.users-list-name {
	display: block;
	font-weight: 600;
	color: $text_main;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.users-list-date {
	display: block;
	color: $gray;
	font-size: 12px;
}
.carousel-control {
	&.left {
		background-image: none;
	}
	&.right {
		background-image: none;
	}
	> .fa {
		font-size: 40px;
		position: absolute;
		top: 50%;
		z-index: 5;
		display: inline-block;
		margin-top: -20px;
	}
}

.box-widget {
	border: none;
	position: relative;
}
.widget-user {
	.widget-user-header {
		padding: 20px;
		height: 120px;
		@include border-radius(3px 3px 0 0);
	}
	.widget-user-username {
		margin-top: 0;
		margin-bottom: 5px;
		font-size: 25px;
		font-weight: 300;
		text-shadow: 0 1px 1px $black_20;
	}
	.widget-user-desc {
		margin-top: 0;
	}
	.widget-user-image {
		position: absolute;
		top: 65px;
		left: 50%;
		margin-left: -45px;
		> img {
			width: 90px;
			height: auto;
			border: 3px solid $white;
		}
	}
	.box-footer {
		padding-top: 30px;
	}
}
.widget-user-2 {
	.widget-user-header {
		padding: 20px;
		@include border-radius(3px 3px 0 0);
	}
	.widget-user-username {
		margin-top: 5px;
		margin-bottom: 5px;
		font-size: 25px;
		font-weight: 300;
		margin-left: 75px;
	}
	.widget-user-desc {
		margin-top: 0;
		margin-left: 75px;
	}
	.widget-user-image > img {
		width: 65px;
		height: auto;
		float: left;
	}
}
.mailbox-controls {
	padding: 5px;
	&.with-border {
		border-bottom: 1px solid $border_line;
	}
}
.mailbox-read-info {
	border-bottom: 1px solid $border_line;
	padding: 10px;
	h3 {
		font-size: 20px;
		margin: 0;
	}
	h5 {
		margin: 0;
		padding: 5px 0 0;
	}
}
.mailbox-read-time {
	color: $gray;
	font-size: 13px;
}
.mailbox-read-message {
	padding: 10px;
}
.mailbox-attachments {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		float: left;
		width: 200px;
		border: 1px solid $border_line;
		margin-bottom: 10px;
		margin-right: 10px;
	}
}
.mailbox-attachment-name {
	font-weight: bold;
	color: $gray;
}
.mailbox-attachment-icon {
	display: block;
	text-align: center;
	font-size: 65px;
	color: $gray;
	padding: 20px 10px;
	&.has-img {
		padding: 0;
		> img {
			max-width: 100%;
			height: auto;
		}
	}
}
.mailbox-attachment-info {
	display: block;
	padding: 10px;
	background: $border_line;
}
.mailbox-attachment-size {
	display: block;
	color: $gray;
	font-size: 12px;
}
.lockscreen {
	background: $border_line;
	.lockscreen-name {
		text-align: center;
		font-weight: 600;
	}
}
.lockscreen-logo {
	font-size: 35px;
	text-align: center;
	margin-bottom: 25px;
	font-weight: 300;
	a {
		color: $text_main;
	}
}
.lockscreen-wrapper {
	max-width: 400px;
	margin: 0 auto;
	margin-top: 10%;
}
.lockscreen-item {
	@include border-radius(4px);
	padding: 0;
	background: $white;
	position: relative;
	margin: 10px auto 30px auto;
	width: 290px;
}
.lockscreen-image {
	@include border-radius(50%);
	position: absolute;
	left: -10px;
	top: -25px;
	background: $white;
	padding: 5px;
	z-index: 10;
	> img {
		@include border-radius(50%);
		width: 70px;
		height: 70px;
	}
}
.lockscreen-credentials {
	margin-left: 70px;
	.form-control {
		border: 0;
	}
	.btn {
		background-color: $white;
		border: 0;
		padding: 0 10px;
	}
}
.lockscreen-footer {
	margin-top: 10px;
}
.login-logo {
	font-size: 35px;
	text-align: center;
	margin-bottom: 25px;
	font-weight: 300;
	a {
		color: $text_main;
	}
}
.register-logo {
	font-size: 35px;
	text-align: center;
	margin-bottom: 25px;
	font-weight: 300;
	a {
		color: $text_main;
	}
}
.login-page {
	background: $border_line;
}
.register-page {
	background: $border_line;
}
.login-box {
	width: 360px;
	margin: 7% auto;
}
.register-box {
	width: 360px;
	margin: 7% auto;
}
.login-box-body {
	background: $white;
	padding: 20px;
	border-top: 0;
	color: $gray;
	.form-control-feedback {
		color: $gray;
	}
}
.register-box-body {
	background: $white;
	padding: 20px;
	border-top: 0;
	color: $gray;
	.form-control-feedback {
		color: $gray;
	}
}
.login-box-msg {
	margin: 0;
	text-align: center;
	padding: 0 20px 20px;
}
.register-box-msg {
	margin: 0;
	text-align: center;
	padding: 0 20px 20px;
}
.social-auth-links {
	margin: 10px 0;
}
.error-page {
	width: 600px;
	margin: 20px auto 0 auto;
	> {
		.headline {
			float: left;
			font-size: 100px;
			font-weight: 300;
		}
		.error-content {
			margin-left: 190px;
			display: block;
			> h3 {
				font-weight: 300;
				font-size: 25px;
			}
		}
	}
}
.invoice {
	position: relative;
	background: $white;
	border: 1px solid $border_line;
	padding: 20px;
	margin: 10px 25px;
}
.invoice-title {
	margin-top: 0;
}
.profile-user-img {
	margin: 0 auto;
	width: 100px;
	padding: 3px;
	border: 3px solid $border_line;
}
.profile-username {
	font-size: 21px;
	margin-top: 5px;
}
.post {
	border-bottom: 1px solid $border_line;
	margin-bottom: 15px;
	padding-bottom: 15px;
	color: $gray;
	&:last-of-type {
		border-bottom: 0;
		margin-bottom: 0;
		padding-bottom: 0;
	}
	.user-block {
		margin-bottom: 15px;
	}
}
.btn-social {
	position: relative;
	padding-left: 44px;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	&.btn-lg {
		padding-left: 61px;
		> :first-child {
			line-height: 45px;
			width: 45px;
			font-size: 1.8em;
		}
	}
	&.btn-sm {
		padding-left: 38px;
		> :first-child {
			line-height: 28px;
			width: 28px;
			font-size: 1.4em;
		}
	}
	&.btn-xs {
		padding-left: 30px;
		> :first-child {
			line-height: 20px;
			width: 20px;
			font-size: 1.2em;
		}
	}
	> :first-child {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 32px;
		line-height: 34px;
		font-size: 1.6em;
		text-align: center;
		border-right: 1px solid $black_20;
	}
}
.btn-social-icon {
	position: relative;
	padding-left: 44px;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 34px;
	width: 34px;
	padding: 0;
	&.btn-lg {
		padding-left: 61px;
		height: 45px;
		width: 45px;
		padding-left: 0;
		padding-right: 0;
		> :first-child {
			line-height: 45px;
			width: 45px;
			font-size: 1.8em;
		}
	}
	&.btn-sm {
		padding-left: 38px;
		height: 30px;
		width: 30px;
		padding-left: 0;
		padding-right: 0;
		> :first-child {
			line-height: 28px;
			width: 28px;
			font-size: 1.4em;
		}
	}
	&.btn-xs {
		padding-left: 30px;
		height: 22px;
		width: 22px;
		padding-left: 0;
		padding-right: 0;
		> :first-child {
			line-height: 20px;
			width: 20px;
			font-size: 1.2em;
		}
	}
	> :first-child {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 32px;
		line-height: 34px;
		font-size: 1.6em;
		border-right: 1px solid $black_20;
		border: none;
		text-align: center;
		width: 100%;
	}
}
.fc-button {
	background: $border_line;
	background-image: none;
	color: $text_main;
	border-color: $border_line;
	border-bottom-color: $border_line;
	&:hover {
		background-color: $background_light;
	}
	&:active {
		background-color: $background_light;
	}
	&.hover {
		background-color: $background_light;
	}
}
.fc-header-right {
	padding-right: 10px;
}
.fc-header-left {
	padding-left: 10px;
}
.fc-widget-header {
	background: $background_light;
	&:first-of-type {
		border-left: 0;
		border-right: 0;
	}
	&:last-of-type {
		border-right: 0;
	}
}
.fc-grid {
	width: 100%;
	border: 0;
}
.fc-widget-content {
	&:first-of-type {
		border-left: 0;
		border-right: 0;
	}
	&:last-of-type {
		border-right: 0;
	}
}
.fc-toolbar {
	padding: 10px;
	margin: 0;
}
.fc-day-number {
	font-size: 20px;
	font-weight: 300;
	padding-right: 10px;
}
.fc-color-picker {
	list-style: none;
	margin: 0;
	padding: 0;
	> li {
		float: left;
		font-size: 30px;
		margin-right: 5px;
		line-height: 30px;
		.fa {
			@include transition(transform linear 0.3s);
			&:hover {
				@include transform(rotate(30deg));
			}
		}
	}
}
#add-new-event {
	@include transition(all linear 0.3s);
}
.external-event {
	padding: 5px 10px;
	font-weight: bold;
	margin-bottom: 4px;
	@include box-shadow(0 1px 1px $black_10);
	text-shadow: 0 1px 1px $black_10;
	@include border-radius(3px);
	cursor: move;
	&:hover {
		@include box-shadow(inset 0 0 90px $black_20);
	}
}
.pad {
	padding: 10px;
}
.margin {
	margin: 10px;
}
.margin-bottom {
	margin-bottom: 20px;
}
.margin-bottom-none {
	margin-bottom: 0;
}
.margin-r-5 {
	margin-right: 5px;
}
.inline {
	display: inline;
}
.description-block {
	display: block;
	margin: 10px 0;
	text-align: center;
	&.margin-bottom {
		margin-bottom: 25px;
	}
	> {
		.description-header {
			margin: 0;
			padding: 0;
			font-weight: 600;
			font-size: 16px;
		}
		.description-text {
			text-transform: uppercase;
		}
	}
	.description-icon {
		font-size: 16px;
	}
}
.label-danger {
	color: $white !important;
	background-color: $btn_error !important;
}
.label-info {
	color: $white !important;
	background-color: $btn_info !important;
}
.label-warning {
	color: $white !important;
	background-color: $btn_warning !important;
}
.label-primary {
	color: $white !important;
	background-color: $btn_primary !important;
}
.label-success {
	color: $white !important;
	background-color: $btn_success_light !important;
}
.text-red {
	color: $btn_error !important;
}
.text-yellow {
	color: $btn_warning !important;
}
.link-muted {
	color: $btn_primary;
	&:hover {
		color: $btn_primary_muted;
	}
	&:focus {
		color: $btn_primary_muted;
	}
}
.link-black {
	color: $gray;
	&:hover {
		color: $gray;
	}
	&:focus {
		color: $gray;
	}
}
.hide {
	display: none !important;
}
.no-border {
	border: 0 !important;
}
.no-padding {
	padding: 0 !important;
}
.no-margin {
	margin: 0 !important;
}
.no-shadow {
	@include box-shadow(none !important);
}
.list-unstyled {
	list-style: none;
	margin: 0;
	padding: 0;
}
.flat {
	@include border-radius(0 !important);
}
.text-bold {
	font-weight: 700;
	&.table {
		td {
			font-weight: 700;
		}
		th {
			font-weight: 700;
		}
	}
}
.text-sm {
	font-size: 12px;
}
.jqstooltip {
	padding: 5px !important;
	width: auto !important;
	height: auto !important;
}
.no-pad-top {
	padding-top: 0;
}
.position-static {
	position: static !important;
}
.list-header {
	font-size: 15px;
	padding: 10px 4px;
	font-weight: bold;
	color: $gray;
}
.list-seperator {
	height: 1px;
	background: $border_line;
	margin: 15px 0 9px;
}
.font-light {
	font-weight: 300;
}
.user-block {
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
	img {
		width: 40px;
		height: 40px;
		float: left;
	}
	.username {
		display: block;
		margin-left: 50px;
		font-size: 16px;
		font-weight: 600;
	}
	.description {
		display: block;
		margin-left: 50px;
		color: $gray;
		font-size: 13px;
	}
	.comment {
		display: block;
		margin-left: 50px;
	}
	&.user-block-sm {
		.username {
			margin-left: 40px;
			font-size: 14px;
		}
		.description {
			margin-left: 40px;
		}
		.comment {
			margin-left: 40px;
		}
		img {
			float: left;
			width: 30px !important;
			height: 30px !important;
		}
	}
}
.img-sm {
	float: left;
	width: 30px !important;
	height: 30px !important;
	+ .img-push {
		margin-left: 40px;
	}
}
.img-md {
	float: left;
	width: 60px;
	height: 60px;
	+ .img-push {
		margin-left: 70px;
	}
}
.img-lg {
	float: left;
	width: 100px;
	height: 100px;
	+ .img-push {
		margin-left: 110px;
	}
}
.img-bordered {
	border: 3px solid $border_line;
	padding: 3px;
}
.img-bordered-sm {
	border: 2px solid $border_line;
	padding: 2px;
}
.attachment-block {
	border: 1px solid $border_line;
	padding: 5px;
	margin-bottom: 10px;
	background: $background_light;
	.attachment-img {
		max-width: 100px;
		max-height: 100px;
		height: auto;
		float: left;
	}
	.attachment-pushed {
		margin-left: 110px;
	}
	.attachment-heading {
		margin: 0;
	}
	.attachment-text {
		color: $gray;
	}
}
.connectedSortable {
	min-height: 100px;
}
.ui-helper-hidden-accessible {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
.sort-highlight {
	background: $border_line;
	border: 1px dashed $border_line;
	margin-bottom: 10px;
}
.full-opacity-hover {
	opacity: 0.65;
	filter: alpha(opacity=65);
	&:hover {
		opacity: 1;
		filter: alpha(opacity=100);
	}
}
.chart {
	position: relative;
	overflow: hidden;
	width: 100%;
	svg {
		width: 100% !important;
	}
	canvas {
		width: 100% !important;
	}
}
.nav-tabs.control-sidebar-tabs > li {
	> a {
		@include border-radius(0);
		border-top: none;
		border-right: none;
		border-left: 1px solid transparent;
		border-bottom: 1px solid transparent;
		&:hover {
			border-top: none;
			border-right: none;
			border-left: 1px solid transparent;
			border-bottom: 1px solid transparent;
		}
		.icon {
			font-size: 16px;
		}
	}
	&:first-of-type > a {
		border-left-width: 0;
		&:hover {
			border-left-width: 0;
		}
		&:focus {
			border-left-width: 0;
		}
	}
	&.active > a {
		border-top: none;
		border-right: none;
		border-bottom: none;
		&:hover {
			border-top: none;
			border-right: none;
			border-bottom: none;
		}
		&:focus {
			border-top: none;
			border-right: none;
			border-bottom: none;
		}
		&:active {
			border-top: none;
			border-right: none;
			border-bottom: none;
		}
	}
}

.input-group .input-group-addon {
	@include border-radius(0);
	border-color: $border_line;
	background-color: $white;
}
.btn-group-vertical .btn.btn-flat {
	&:first-of-type {
		@include border-radius(0);
	}
	&:last-of-type {
		@include border-radius(0);
	}
}

.form-control-feedback.fa {
	line-height: 34px;
}
.input-lg + .form-control-feedback.fa {
	line-height: 46px;
}
.input-group-lg + .form-control-feedback.fa {
	line-height: 46px;
}
.form-group-lg .form-control + .form-control-feedback.fa {
	line-height: 46px;
}
.input-sm + .form-control-feedback.fa {
	line-height: 30px;
}
.input-group-sm + .form-control-feedback.fa {
	line-height: 30px;
}
.form-group-sm .form-control + .form-control-feedback.fa {
	line-height: 30px;
}
.collapsed-box .box-header.with-border {
	border-bottom: none;
}
.no-header .box-body {
	@include border-radius(3px 3px 0 0);
}
.timeline-inverse > li > .timeline-item {
	background: $border_line;
	border: 1px solid $border_line;
	@include box-shadow(none);
	> .timeline-header {
		border-bottom-color: $border_line;
	}
}
.btn[class*='bg-']:hover {
	@include box-shadow(inset 0 0 100px $black_20);
}
.product-list-in-box > .item {
	@include box-shadow(none);
	@include border-radius(0);
	border-bottom: 1px solid $border_line;
	&:last-of-type {
		border-bottom-width: 0;
	}
}
table.text-center {
	text-align: center;
	td {
		text-align: center;
	}
	th {
		text-align: center;
	}
}
.direct-chat-contacts-open .direct-chat-contacts {
	@include transform(translate(0, 0));
}
.direct-chat-danger .right > .direct-chat-text {
	background: $btn_error;
	border-color: $btn_error;
	color: $white;
	&:after {
		border-left-color: $btn_error;
	}
	&:before {
		border-left-color: $btn_error;
	}
}
.direct-chat-primary .right > .direct-chat-text {
	background: $btn_primary;
	border-color: $btn_primary;
	color: $white;
	&:after {
		border-left-color: $btn_primary;
	}
	&:before {
		border-left-color: $btn_primary;
	}
}
.direct-chat-warning .right > .direct-chat-text {
	background: $btn_warning;
	border-color: $btn_warning;
	color: $white;
	&:after {
		border-left-color: $btn_warning;
	}
	&:before {
		border-left-color: $btn_warning;
	}
}
.direct-chat-success .right > .direct-chat-text {
	background: $btn_success_light;
	border-color: $btn_success_light;
	color: $white;
	&:after {
		border-left-color: $btn_success_light;
	}
	&:before {
		border-left-color: $btn_success_light;
	}
}
.mailbox-messages > .table {
	margin: 0;
}
.fc-header-title h2 {
	font-size: 15px;
	line-height: 1.6em;
	color: $gray;
	margin-left: 10px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
	padding-left: 0;
	padding-right: 0;
	height: auto;
	margin-top: -4px;
	padding-right: 10px;
}
.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
	padding-right: 6px;
	padding-left: 20px;
}
.select2-search--inline .select2-search__field {
	border: 1px solid $border_line;
	&:focus {
		outline: none;
	}
}
[class^="bg-"].disabled {
	opacity: 0.65;
	filter: alpha(opacity=65);
}
.list-group-unbordered > .list-group-item {
	border-left: 0;
	border-right: 0;
	@include border-radius(0);
	padding-left: 0;
	padding-right: 0;
}
.list-link > a {
	padding: 4px;
	color: $gray;
	&:hover {
		color: $gray_dark;
	}
}