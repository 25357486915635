.show-option {
    cursor: pointer;
    &.active {
        p {
            border: $background_light;
            color: $background_light;
        }
    }
}
.option-input {
    &.has-warning {
        border-color: $btn_warning_light;
        outline: 0;
        box-shadow: 0 0 0 0.2rem $btn_warning_transparent;
    }
    &.has-success {
        border-color: $btn_success_light;
        outline: 0;
        box-shadow: 0 0 0 0.2rem $btn_success_transparent
    }
}
.option-save,
.option-download {
    position: absolute;
    bottom: 1rem;
}

.option-notifications {
    .popup {
        margin-top: 10px;
        padding: 10px;
        background-color: $btn_success_light;
        border: 1px solid $btn_success_modal;
        border-radius: 3px;
        color: $white;
    }
}

#alert-wrapper {
    position: absolute;
    top: 55px;
    right: 25px;
    z-index: 9999;
    width: 80%;
}
.alert,
.option-notifications {
    float: right;
    clear: both;
}

input[type="number"] {
    -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}