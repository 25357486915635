.auth-form {
    .checkheck .checkbox {
        left: -20px;
        top: calc(50% - -10px);
    }
}

.form-check {
    padding-top: calc(0.375rem + 1px);
}

label.required {
    &::after{
        content: "*";
        color: red;
    }
}

.progress {
    text-align: center;
    position: relative;
    line-height: 1rem;
    span {
        position: absolute;
        left: 50%;
        font-size: 12px;
        @include transform(translateX(-50%));
    }
}

.spinner {
    position: absolute;
    height: 100%;
    width: 100%;
    display: none;
    z-index: 9999999;
    background-color: rgba(130, 130, 147, 0.3);
    &.spinning+canvas {
        opacity: 0.3;
    }
    i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 4rem;
        color: $main-color;
    }
}

.roles {
    #roles-table {
        .form-group {
            div:first-child {
                float: left;
                margin-right: 5px;
            }
        }
    }
}

.actions {
    i {
        cursor: pointer;
        color: $btn_primary;
        &:hover {
            outline: none;
            text-decoration: none;
            color: $btn_primary_light;
        }

        &.delete {
            color: $btn_error;
            &:hover {
                outline: none;
                text-decoration: none;
                color: $btn_error_light;
            }
        }
    }
}

.login {
    .remember {
        margin-bottom: 30px;
    }
}

.text-muted {
    font-size: 0.7rem;
}

.hidden {
    display: none;
}
.full-hidden {
    display: none !important;
    opacity: 0;
    position: absolute;
    z-index: 0;
    top: -9999;
}

.exists {
    color: green !important;
}

.form-control-nav {
    padding-left: 0;
    .btn {
        margin-right: 15px;
    }
}

label.empty {
    color: transparent !important;
    & + * {
        display: block;
    }
}

.table .fa,
.table .fas,
.table .far {
    font-size: 1.8em;
    margin-right: 10px;
}

.link {
    color: $main_color;
}

#notification {
    padding: 20px;
}

.admin-only {
    input, select, .select2 {
        box-shadow: 0px 0px 5px $btn_error;
    }
}

.content-wrapper {
    @include media-breakpoint-down(sm) {
        h1 {
            font-size: 1.8rem;
        }
        h2 {
            font-size: 1.5rem;
        }
        h3, h4 {
            font-size: 1rem;
        }
        .content{
            padding-top: 0;
        }
        min-height: auto !important;
    }
}
.content {
    height: 100%;
    article {
        height: 100%;
        > .container-fluid {
            height: 100%;
        }
    }
}
.wrapper {
    @include media-breakpoint-down(sm) {
        overflow-y: hidden !important;
    }
}


.input-group .select2-container {
    width: calc(100% - 39px) !important;
}
/**
    STATISTICS
**/
.user-stats {
    min-height: 300px;
}
.date-range-field {
    width: 100%;
    padding-top: 15px;
    input, select {
        text-align: center;
    }
}

/**
    CALENDAR
**/
.shift-lock {
    position: relative;
    width: 100%;
    i {
        position: absolute;
        display: none;
        right: 0;
        top: -10px;
        font-size: 3em;
        color: gray;
        opacity: 0.5;
    }
}
.disenable-edit {
    .can-edit,
    #shift-update,
    .shift-delete,
    #openReportPopup,
    #openDonationPopup {
        display: none;
    }
    .shift-lock i {
        display: block;
    }
}
.popup {
    position: absolute;
    top: -20px;
    right: 0;
    background-color: #fff;
    padding: 5px 7px;
    border-radius: 3px;
    text-align: center;
    z-index: 9999;
    border: 1px solid #e34e09;
}
#hover-popup {
    position: absolute;
    display: none;
    bottom: 50px;
    right: 50px;
    z-index: 1500;
    border: 1px solid $secondary_color;
    padding: 3px 7px;
    border-radius: 3px;
    background-color: $main-color;
    color: white;
}
.calander-popup-modal {
    .modal-content {
        &.user-confirmed {
            background-color: #b4f2ae;
            #shift-user-confirm {
                display: none;
            }
        }
        &.admin-confirmed {
            background-color: #94ebff;
            #shift-admin-confirm{
                display: none;
            }
            &.user-confirmed {
                background-image: linear-gradient(45deg,#b4f2ae 25%,transparent 25%,transparent 50%,#b4f2ae 50%,#b4f2ae 75%,transparent 75%,transparent);
                background-size: 15rem 15rem;
            }
        }
    }
}
#modalShiftId.adminSee {
    background: transparent;
    border: none;
    font-size: 1.2rem;
    width: 100px;
    margin-top: 38px;
    position: absolute;
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active {
        border: none;
    }
}

#donation-table {
    font-size: 12px;
    th, td {
        padding: 6px;
        line-height: 12px;
    }
}

.fc-timegrid-event .fc-event-time,
.fc-v-event .fc-event-title {
    background-color: $main-color;
    color: white;
    padding: 3px;
    border-radius: 4px;
    font-size: var(--fc-small-font-size,.85em);
}
.fc .fc-list-sticky .fc-list-day > * {
    z-index: 1;
}

.home-filters {
    .calendar-time,
    .drp-buttons {
        display: none;
    }
}
/**
    USERS
**/
#users-table {
    .inactive {
        background-color: #ff000021 !important;
    }
    .on-break {
        background-color: #00b7ff29 !important;
    }
}
#contracts-table {
    .payroll-generate {
        color: $main_color_2;
    }
}

/**
    REPORTS AND DIARIES
**/
.drp-calendar.right {
    display: none !important;
}
.tox.tox-tinymce {
    width: 100%;
}
.tox-statusbar__branding {
    display: none;
}