.select2-container--default {
	&.select2-container--focus {
		outline: none;
		.select2-selection--multiple {
			border-color: $btn_primary !important;
			border-color: $border_line;
		}
	}
	&:focus {
		outline: none;
	}
	&:active {
		outline: none;
	}
	.select2-selection--single {
		border: 1px solid $border_line;
		@include border-radius(0);
		padding: 6px 12px;
		height: 37px;
		.select2-selection__arrow {
			height: 32px;
			right: 3px;
			b {
				margin-top: 0;
			}
		}
		.select2-selection__rendered {
			line-height: 30px;
		}
	}
	&.select2-container--open {
		border-color: $btn_primary;
    }
    .select2-results__option {
        padding: 6px 12px;
        user-select: none;
        &--highlighted[aria-selected] {
            background-color: $btn_primary;
            color: $white;
        }
        &[aria-disabled=true] {
            color: $gray;
            display: none;
        }
        &[aria-selected=true] {
            background-color: $border_line;
            color: $white;
            &:hover {
                color: $white;
            }
        }
    }
	.select2-selection--multiple {
		border: 1px solid $border_line;
		@include border-radius(0);
		&:focus {
			border-color: $btn_primary;
		}
		.select2-selection__choice {
			background-color: $btn_primary;
			border-color: $btn_primary;
			padding: 1px 10px;
			color: $white;
		}
		.select2-selection__choice__remove {
			margin-right: 5px;
			color: $white_70;
			&:hover {
				color: $white;
			}
		}
	}
	.select2-search--dropdown .select2-search__field {
		border-color: $btn_primary !important;
	}
}
.select2-selection {
	&.select2-container--focus {
		outline: none;
	}
	&:focus {
		outline: none;
	}
	&:active {
		outline: none;
	}
	.select2-selection--single {
		border: 1px solid $border_line;
		@include border-radius(0);
		padding: 6px 12px;
		height: 34px;
	}
}
.select2-dropdown {
	border: 1px solid $border_line;
	@include border-radius(0);
	.select2-search__field {
		border: 1px solid $border_line;
		&:focus {
			outline: none;
		}
	}
}
.select2-container {
	z-index: 2001;
    width: 100% !important;
}